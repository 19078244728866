var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isAuthTokenSet && _vm.isAppReady)?_c('div',{class:['app', _vm.pointerEventNone && 'pointer'],attrs:{"id":"app"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.shouldDisplayDefaultBackgroundPatterns),expression:"shouldDisplayDefaultBackgroundPatterns"}],staticClass:"app__background"},[_c('div',{staticClass:"app__pattern app__pattern--one"}),_c('div',{staticClass:"app__pattern app__pattern--two"})]),_c('nav-header',{attrs:{"headerInput":_vm.headerNav,"signalRStrip":_vm.signalRStripData,"routeList":_vm.routeList,"routeOn":_vm.subHeaders,"indicationInfo":_vm.indicationData},on:{"loadNav":_vm.navToLoad,"setIndicationData":_vm.setIndicationData,"event":_vm.callback}}),_c('error-popup',{attrs:{"errorResponse":_vm.errorResponse},on:{"showErrorPopup":function($event){return _vm.showErrorPopup($event)},"noAction":function($event){return _vm.noAction($event)}}}),_c('div',{staticClass:"app__container",style:([_vm.isMobileView && { 'padding-top': _vm.paddingTop + 'px' }])},[_c('router-view',{class:[
    'main-height',
    _vm.indicationData.showStrip || _vm.signalRStripData.stripShow
      ? 'mt-120'
      : 'mt-80',
    _vm.routeOn.subHeader && 'root-container',
    this.$route.path === '/planner' ? 'planner-component' : '',
    this.$route.path === '/account/programs' ? 'account-programs' : ''
  ],attrs:{"id":"rootContainer","signalRStrip":_vm.signalRStripData,"userDetails":{
    id: _vm.id,
    firstName: _vm.firstName,
    lastName: _vm.lastName,
    profilePicture: _vm.profilePicture,
    email: _vm.email,
    accountID: _vm.accountID,
    userLabel: _vm.userLabel,
    progName: _vm.progName,
    programId: _vm.programId,
    siteId: _vm.siteId,
    programStartDate: _vm.programStartDate,
    programEndDate: _vm.programEndDate,
    sitePanels: _vm.sitePanels,
    roleId: _vm.roleId,
    siteDetailData: _vm.siteDetailData
  },"roleId":_vm.roleId},on:{"loadNav":_vm.navToLoad,"loadNavs":_vm.navToLoad,"indicationStrip":_vm.visitProgramAdmin,"update_userDt":_vm.updateUser,"showSignalRStripIndicater":_vm.showSignalRStrip,"updateDataLayer":_vm.updateDataLayer,"setIndicationData":_vm.setIndicationData,"showBlocker":function($event){return _vm.showBlocker($event)},"noAction":function($event){return _vm.noAction($event)},"updateSiteAtApp":function($event){return _vm.updateSite($event)},"updateDrillSiteName":function($event){return _vm.updateDrillSiteName($event)},"updateDrillProgramName":_vm.updateDrillProgramName}})],1),_c('footer-comp',{staticClass:"app__footer",attrs:{"routeOn":_vm.subHeaders}}),_c('portal-target',{attrs:{"name":"modal"}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }