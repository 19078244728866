





































































































































































































































import ProgramList from '@/components/partnerHome/components/programList/ProgramList';
export default ProgramList;
