import { Vue, Component, Prop, Watch, Inject } from 'vue-property-decorator';
import { IHeader } from './models/IHeader';
import UIkit from "uikit";
import ProgramList from '@/components/partnerHome/components/programList/ProgramList.vue'
import APP_UTILITIES from '@/utilities/commonFunctions';
import { stopUpload } from '@/services/scholar/scholarService'
import programListStore from "@/store/modules/programList";
import DeleteRosterConfirmation from '@/popupcomponents/DeleteRosterConfirmation/DeleteRosterConfirmation.vue';
import APP_CONST from '@/constants/AppConst';
import headerStore from '@/store/modules/headerStore';
import { checkCMSEnabledReRoute } from '@/utilities/cmsUtilities';
import { AnalyticsInjectionKey, AnalyticsService, analyticsEventNames } from '@/analytics';

@Component({
  name: 'Header',
  components: {
    'deleteRosterConfirmation': DeleteRosterConfirmation,
    'program-list': ProgramList
  },
  filters: {
    capitalizeProgram: (value: string) => {
      const { roleId } = APP_UTILITIES.coreids()
      if (!value) return ''
      const actualVal = roleId === 5 ? value.toUpperCase() : value;
      return actualVal;
    }
  }
})
export default class Header extends Vue {
  @Inject(AnalyticsInjectionKey)
  private readonly analyticsService!: AnalyticsService;

  selectedProgram: any;
  isActive: boolean = false;
  listIndex: number | null = null;
  hoverIn: boolean = false;
  headerFixed: boolean = false;
  hoverId: string = '';
  hoverText: string = '';
  showTooltip: boolean = false;
  isShowStopPopup: boolean = false;
  styleObj = {}
  public currentRoleId: number = 0;
  public isMobileView: boolean = false;
  routeNames = ['Partner Home', 'Manage Users', 'Submissions', 'Enrolled Scholars', 'Manage Applications', 'Applications', 'Forms', 'Scholar', 'Guardian', 'Contacts', 'Roster']
  hideBackArrowArray: string[] = ['SITE/SESSION', 'ADD A NEW PROGRAM', 'ACCOUNT DETAILS', 'MANAGE ACCOUNT', 'EDIT PROGRAM']
  program: any = {};
  userManagementEnabled: boolean = false;

  @Prop({ default: function () { return { navView: [] } } })
  headerInput!: IHeader;

  @Prop()
  routeList!: string[];

  @Prop()
  signalRStrip!: { component: string, stripShow: boolean, stripText: string }

  @Prop({ default: function () { return { title: '', returnTo: '', subHeader: false, bgColor: '' } } })
  routeOn!: { title: string; returnTo: string; subHeader: boolean; bgColor: string };

  @Prop({ default: function () { return { showStrip: false, stripText: '', name: '', hover: false } } })
  indicationInfo!: { showStrip: boolean, stripText: string, name: string, hover: boolean }

  /* istanbul ignore next */
  get drilledProgram() {
    this.isActive = false;
    if (programListStore.drilledProgram && programListStore.drilledProgram.programName != undefined && programListStore.drilledProgram.programName.length) {
      this.selectedProgram = programListStore.drilledProgram;
    }
    return this.selectedProgram;
  }

  /* istanbul ignore next */
  get bxDrillToAccount() {
    return headerStore.bxDrillToAccount;
  }

  /* istanbul ignore next */
  get redirectURL() {
    const partnerRole = [APP_CONST.ROLE_TYPE_ACCOUNT_ADMIN, APP_CONST.ROLE_TYPE_PROGRAM_ADMIN, APP_CONST.ROLE_TYPE_SESSION_ADMIN, APP_CONST.ROLE_TYPE_STAFF, APP_CONST.ZERO].includes(this.headerInput.roleType);
    return partnerRole ? '/partnerhome' : '/home';
  }

  /* istanbul ignore next */
  get superDrill() {
    const bxDrill = !this.bxDrillToAccount || [1, 2, 3].includes(this.headerInput.roleType);
    const home = bxDrill ? this.redirectURL : '/partnerhome';
    return home;
  }

  /* istanbul ignore next */
  get programsList() {
    return programListStore.programListFilters;
  }

  /* istanbul ignore next */
  get lxEnabled() {
    const appSet = programListStore && programListStore.appSetting;
    const accountHasFeature = !checkCMSEnabledReRoute(appSet);
    return accountHasFeature;
  }

  /* istanbul ignore next */
  get filteredHeaderInput() {
    const { roleId, siteId, programId } = APP_UTILITIES.coreids();
    let loggedInRole: any = APP_UTILITIES.getCookie("highest_role");
    loggedInRole = Number(loggedInRole);
    if (this.bxDrillToAccount) {
      if ((roleId === APP_CONST.ZERO || roleId === APP_CONST.ACCOUNT_ADMIN_ROLE_ID) && this.headerInput.navView[0].nestedDrop) {
        APP_UTILITIES.findAndSplice(this.headerInput.navView[0].nestedDrop, 'title', 'Account');
      } else if (roleId === APP_CONST.PROGRAM_ADMIN_ROLE_ID && this.headerInput.navView[3].nestedDrop) {
        APP_UTILITIES.findAndSplice(this.headerInput.navView[3].nestedDrop, 'title', 'Account');
      } else if (roleId === APP_CONST.SESSION_ADMIN_ROLE_ID && this.headerInput.navView[4].nestedDrop) {
        APP_UTILITIES.findAndSplice(this.headerInput.navView[4].nestedDrop, 'title', 'Account');
      }
    } else {
      let highestRole: any = APP_UTILITIES.getCookie("highest_role");
      highestRole = Number(highestRole);
      if (roleId === APP_CONST.ZERO && highestRole == APP_CONST.ROLE_TYPE_STAFF && this.headerInput.navView[0].nestedDrop) {
        APP_UTILITIES.findAndSplice(this.headerInput.navView[0].nestedDrop, 'title', 'Account');
      }
    }
    if ((roleId === 0 && siteId && this.headerInput.navView[0].nestedDrop) ||
      ((loggedInRole === APP_CONST.SESSION_ADMIN_ROLE_ID || loggedInRole === APP_CONST.STAFF_ROLE_ID) && this.headerInput.navView[0].nestedDrop)) {
      APP_UTILITIES.findAndSplice(this.headerInput.navView[0].nestedDrop, 'title', 'Family records');
      APP_UTILITIES.findAndSplice(this.headerInput.navView[0].nestedDrop, 'title', 'Enrollment manager');
    }

    if (programId) {
      this.programsList && this.programsList.length && this.programsList.forEach((element: any) => {
        if (element && element.programId == programId) {
          this.program = element;
        }
      });
      if (siteId && this.program.status == 2) {
        APP_UTILITIES.findAndSplice(this.headerInput.navView, 'title', 'Learning & Resources');
      }
    }
    if ((loggedInRole == APP_CONST.ROLE_TYPE_BX_CONTENT_ADMIN || loggedInRole == APP_CONST.ROLE_TYPE_SESSION_ADMIN || loggedInRole == APP_CONST.ROLE_TYPE_STAFF || loggedInRole == APP_CONST.ROLE_TYPE_NONSYSTEM) && !this.headerInput.navView[0].nestedDrop) {
      this.headerInput.navView.forEach((header: any, navIndex: number) => {
        if (header.title == "Analytics") {
          APP_UTILITIES.findAndSplice(header.nestedDrop, 'title', "Professional Learning");
        }
      });
    }
    this.headerInput.navView.forEach(async (header: any, navIndex: number) => {
      if (header.title == APP_CONST.LEARNING_AND_RESOURCES) {
        APP_UTILITIES.findAndSplice(this.headerInput.navView, 'title', APP_CONST.LEARNING_AND_RESOURCES);
        const all = await APP_UTILITIES.roleBasedNav();
        const cmsDrop = all.navView.filter((item: any) => {
          const { title = '' } = item;
          if (title === APP_CONST.LEARNING) {
            return true;
          }
          return false;
        });
        this.headerInput.navView.splice(navIndex, 0, cmsDrop[0]);
      }

      if (this.userManagementEnabled && header.nestedDrop) {
        APP_UTILITIES.findAndSplice(header.nestedDrop, 'title', 'Family records');
      }
    });
    return this.headerInput;
  }

  /* istanbul ignore next */
  get routePointed() {
    return this.$route.name;
  }

  /* istanbul ignore next */
  get showBackArrow() {
    if (this.hideBackArrowArray.includes(this.routeOn.title)) {
      return false;
    }
    return true;
  }


  async beforeMount() {
    this.userManagementEnabled = await APP_UTILITIES.getFeatureFlag(APP_CONST.FEATURE_KEYS.userManagementNavigationAndTable);

    programListStore.getAppSettings();
  }

  /* istanbul ignore next */
  mounted() {
    document.addEventListener("scroll", this.handleHeaderScroll);
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
    this.isMobile();
  }

  @Watch('routeOn', { immediate: true, deep: true })
  updatePreviousRoute(data: any) {
    programListStore.updatePreviousRoute(data);
  }

  @Watch('$route', { immediate: true, deep: true })
  highlightSelectedNav(route: any) {
    const index = this.headerInput.navView.findIndex((nav, index) => {
      let navSelected = false;
      if (!nav.isNested && (nav.route === route.path || (nav.relatedRoutes && nav.relatedRoutes.includes(route.path)))) {
        navSelected = true;
      } else if (nav.isNested && nav.nestedDrop) {
        nav.nestedDrop.forEach((nestedNav) => {
          const [initialRoute] = route.matched;
          if (initialRoute && initialRoute.path && new RegExp(initialRoute.path).test(nestedNav.route)) {
            navSelected = true;
          } else if (nestedNav.route === route.path) {
            navSelected = true;
          }
        })
      }
      return navSelected;
    })
    this.headerInput.navView.forEach((el) => {
      el.isSelected = false;
    })
    if (index >= 0) {
      this.selected(index);
    }
  }

  hideOrangeBar() {
    Vue.prototype.$eventHub.$emit("showAttendanceUndoStripIndicater", {
      component: "",
      stripShow: false,
      attendanceStripShow: false,
    });
  }

  openDeteleRoster() {
    if (UIkit.modal('#Stop-Roster-confirmationPopUp')) {
      UIkit.modal('#Stop-Roster-confirmationPopUp').show();
    }
  }

  closeDeleteRosterPopup() {
    if (UIkit.modal('#Stop-Roster-confirmationPopUp')) {
      UIkit.modal('#Stop-Roster-confirmationPopUp').hide();
    }
  }

  undoAction() {
    Vue.prototype.$eventHub.$emit('undoChanges')
  }

  async stopRoster() {
    var userRoles: any = APP_UTILITIES.getCookie("user_role");
    var userData = JSON.parse(userRoles);
    this.currentRoleId = userData[0].siteId;
    let response: any = await stopUpload(this.currentRoleId)
    /* istanbul ignore else */

    if (response.status == 200) {
      this.$emit("signalRStrip", { component: "", stripShow: false, stripText: '' })
      this.closeDeleteRosterPopup();
    }
    if (response.status == 400) {
      this.closeDeleteRosterPopup();
    }
  }

  errorDetailNavigate() {
    this.$router.push('/roster/new');
  }
  trim(x: string) {
    return (x && x.replace(/^\s+|\s+$/gm, '')) || '';
  }

  protected routeAvailability(route: string) {
    const matched = this.$router.getMatchedComponents(route);
    if (matched.length > 0) {
      return true;
    }
    return false;
  }

  protected redirectRoute(baseUrl: string) {
    if (this.trim(baseUrl)) {
      const { roleId } = APP_UTILITIES.coreids();
      if (roleId === 0 && (baseUrl.includes("/enroll/submissions") || baseUrl.includes("/families/scholar"))) {
        const asd = APP_UTILITIES.getCookie("user_role");
        let user_role = asd && JSON.parse(asd);
        user_role[0].roleId = APP_CONST.ROLE_TYPE_PROGRAM_ADMIN;
        user_role = JSON.stringify(user_role);
        APP_UTILITIES.setCookie("user_role", user_role, APP_CONST.ONE);
      }
      window.location.href = baseUrl;
      this.isActive = false;
      document.body.classList.remove('body_class');
    }
  }

  public visitRoute(route: string) {
    route.search('account') && this.analyticsService.track(analyticsEventNames.PROGRAM_LIST_VIEWED);
    if (route.includes(APP_CONST.APP_ROUTES.CMS.path)) {
      //CMS Route
      this.$router.push(route);
    }
    if (route == 'navigateUrl') {
      let callEvent = 'navigateUrl';
      let el = {
        callback: "navigateUrl",
        id: APP_CONST.ONE
      }
      this.emitEvent(callEvent, el)
    }
    else {
      const baseUrl = this.headerInput.baseUrl;
      if (this.routeAvailability(route)) {
        if (route === '/partnerhome') {
          APP_UTILITIES.deleteCookie('selectedProgramStatus');
          APP_UTILITIES.setCookie("progName", '', 1);
          APP_UTILITIES.setCookie("programId", '', 1);
          let userRoles: any = APP_UTILITIES.getCookie("user_role");
          let userData = JSON.parse(userRoles);
          userData[0].roleId = APP_CONST.ROLE_TYPE_ACCOUNT_ADMIN;
          userData[0].programId = APP_CONST.ZERO;
          APP_UTILITIES.setCookie("super_user_drilldown", 'true', 1);
          APP_UTILITIES.setCookie("super_user_drilldown_to_account", 'true', 1);
          APP_UTILITIES.setCookie("user_role", JSON.stringify(userData), 1);
          this.$emit('loadNav');
          this.$emit('setIndicationData');
        }
        if (this.$route.path != route) {
          this.$router.push(route);
        }
        this.isActive = false;
        document.body.classList.remove('body_class');
      } else {
        if (baseUrl) {
          this.redirectRoute(baseUrl);
        }
      }
    }
  }

  selected(index: number) {
    this.headerInput.navView[index].isSelected = true;
  }

  emitEvent(callEvent: string, el: any) {
    this.listIndex = -1;
    this.$emit('event', { callEvent, data: el });
    APP_UTILITIES.deleteCookie('selectedProgramStatus');
    this.clearProgramFromLocal();

    if (el && el.title === 'Help center') {
      this.analyticsService.track(analyticsEventNames.HELP_CENTER_CLICKED);
    }
  }

  clearProgramFromLocal() {
    APP_UTILITIES.deleteFromLocalStorage('enrollmentselectedprogram');
    APP_UTILITIES.deleteFromLocalStorage('retentionselectedprogram');
    APP_UTILITIES.deleteFromLocalStorage('attendanceselectedprogram');
    APP_UTILITIES.deleteFromLocalStorage('applicationselectedprogram');
    APP_UTILITIES.deleteFromLocalStorage('qualityselectedprogram');
  }

  emitEventForMobile(ev: { callEvent: string, data: object }) {
    this.$emit('event', { callEvent: ev.callEvent, data: ev.data });
  }


  handleHeaderScroll() {
    if (window.scrollY > 50) {
      this.headerFixed = true;
    }
    else {
      this.headerFixed = false;
    }
  }

  toggleNavDropdown(index: number) {
    this.headerInput.navView.forEach((element, idx) => {
      if (idx === index) {
        element.openStatus = !element.openStatus;
      }
      else {
        element.openStatus = false;
      }
    });
  }

  openNavDropdown(index: number) {
    this.headerInput.navView[index].openStatus = true;
  }

  closeNavDropdown(index: number) {
    this.headerInput.navView[index].openStatus = false;
  }

  checkImages(route: string) {
    const images = ['Home', 'Program Planner', 'Resources', 'Sites', 'Scholar Roaster', 'Attendance', 'Manage Users', 'Manage Families', 'Dashboard', 'Staff Roster', 'Manage Program'];
    if (images.includes(route)) {
      return true;
    } else {
      return false;
    }
  }

  mobileHeaders() {
    if (UIkit.offcanvas('#mobile-header')) {
      UIkit.offcanvas('#mobile-header').show();
    }
  }

  back() {
    const returnTo = this.routeOn.returnTo;
    if (returnTo) {
      this.$router.push(returnTo);
    }
  }

  checkRoute() {
    return !(this.routePointed && this.routeList.includes(this.routePointed));
  }

  onResize() {
    const width = document.documentElement.clientWidth;
    const mobileHeaderHide = document.getElementById("mobile-header");
    if (mobileHeaderHide != null) {
      if (width > 992 && mobileHeaderHide.style.display == "block") {
        this.closeModal();
      }
    }
  }

  loadNav() {
    this.$emit('loadNav');
  }

  toggleMenu() {
    this.isActive = !this.isActive;
    const width = document.documentElement.clientWidth;
    if (this.isActive && width < 992) {
      document.body.classList.add('body_class');
    } else {
      document.body.classList.remove('body_class');
    }
  }

  closeModal() {
    if (UIkit.offcanvas('#mobile-header')) {
      UIkit.offcanvas('#mobile-header').hide();
    }
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  }
  callHover(value: string, event: any, id: string) {
    this.hoverId = id;
    this.hoverText = value;
    this.checkDivLength();
    const boundBox = event && event.target && event.target.getBoundingClientRect();
    const coordX = boundBox.left;
    const coordY = boundBox.top;
    this.styleObj = {
      top: (coordY + 40).toString() + "px",
      left: (coordX - 20).toString() + "px",
      'text-transform': 'none'
    }
  }
  callHoverOut() {
    this.hoverId = '';
    this.hoverText = '';
  }
  checkDivLength() {
    const userInformation = <HTMLSpanElement>document.querySelector('.text-none');
    if (userInformation) {
      const computedStyle = window.getComputedStyle(userInformation);
      const nameWidth = computedStyle.width || '';
      const nameWidthInSpliced = nameWidth.slice(0, 7);
      if (nameWidthInSpliced && parseFloat(nameWidthInSpliced) > 96.8438) {
        this.showTooltip = true;
      }
    }
  }

  isMobile() {
    this.isMobileView = APP_UTILITIES.isMobile();
  }

  closeProgramStatusDropdown() {
    let programList = this.$refs && this.$refs.programList as any;
    if (programList) {
      programList.closeStatusDropdown();
    }
  }
}
