import store from "@/store";
import {
    getModule,
    Module,
    VuexModule,
    Mutation
} from "vuex-module-decorators";
import APP_UTILITIES from "@/utilities/commonFunctions";

@Module({
    namespaced: true,
    name: "headerStore",
    store,
    dynamic: true
})

class HeaderModule extends VuexModule {
    isBxDrillToAccount: boolean = false;

    get bxDrillToAccount() {
        const bxDrillToProgram = Boolean(APP_UTILITIES.getCookie('super_user_drilldown'));
        const bxDrillToAccount = Boolean(APP_UTILITIES.getCookie('super_user_drilldown_to_account'))
        return this.isBxDrillToAccount ? this.isBxDrillToAccount : (bxDrillToProgram || bxDrillToAccount);
    }

    @Mutation 
    mutateBxState(value: boolean) {
        this.isBxDrillToAccount = value;
    }
}
export default getModule(HeaderModule);