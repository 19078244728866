import { max, min_value, required } from 'vee-validate/dist/rules';
import { ValidationRule } from 'vee-validate/dist/types/types';

export const rules: Record<string, ValidationRule> = {
  max,
  min_value,
  required,
  unique: {
    validate: async (value: string, {validator}: Record<string, any>) => {
      const {data: exists} = await validator(value);
      return !exists;
    },
    message: 'The {_field_} must be unique.',
    params: ['validator']
  }
};
