import {
  getSaveConfigureResponse,
  getCreateProgramRes,
  updateProgramStatus,
  updateProgramWithEditedDataAPI,
  getFetchConfigResponse,
  getFetchConfigResponseProgram,
  getFirstTimeTasks,
  getAutoSaveProgram,
  getSavedProgramData,
  FetchConfigProgByIdResponse,
  editConfigureResponse,
  sendProgramInvite,
  getFetchProgramDetails,
  saveSiteSessionDetails,
} from "@/services/userService/users-api";
import store from "@/store";
import {
  getModule,
  Module,
  VuexModule,
  Mutation,
  Action
} from "vuex-module-decorators";
import { ProgramConfigureObj, CreateProgramObj } from '@/Model/model';

@Module({
  namespaced: true,
  name: "programConfigureStore",
  store,
  dynamic: true
})

class ProgramConfigureModule extends VuexModule {
  private configureSaveRes: any = {}
  private createProgramSaveRes: any = {}
  public UpdatedProgramRes: any = {}
  public fetchUpdatedProgram: any = {}
  public firstTimeTasks: any = {}
  public productId: number=0
  public siteSessionRes:any = {}

  get initialTasks(): object {
    return this.firstTimeTasks;
  }

  get configureResponse(): object {
    return this.configureSaveRes;
  }

  get createProgramResponse(): object {
    return this.createProgramSaveRes;
  }

  get UpdatedProgramResponse(): object {
    return this.UpdatedProgramRes;
  }

  get updatedProgramObj(): object {
    return this.fetchUpdatedProgram;
  }

  @Mutation
  public afterCreateProgramMutate(payload: any) {
    this.configureSaveRes = payload.data;
  }


  @Action({ commit: "afterCreateProgramMutate" })
  public createNewProgram(objToSave: CreateProgramObj): Promise<Object> {
    let resultNew = async function getSaveResponse(objToSave: CreateProgramObj): Promise<Object> {
      let promise = getCreateProgramRes(objToSave);
      let result = await promise; 
      return result as object;
    }
    return resultNew(objToSave);
  }
  @Action({ commit: "afterCreateProgramMutate" })
  public sendInviteProgram(objToSave: CreateProgramObj): Promise<Object> {
    let resultNew = async function getSaveResponse(objToSave: CreateProgramObj): Promise<Object> {
      let promise = sendProgramInvite(objToSave);
      let result = await promise; 
      return result as object;
    }
    return resultNew(objToSave);
  }
  

  @Mutation
  public afterConfigureMutate(payload: any) {
    this.configureSaveRes = payload.data;
  }


  @Action({ commit: "afterConfigureMutate" })
  public saveProgramConfigure(objToSave: ProgramConfigureObj): Promise<Object> {
    let resultNew = async function getSaveResponse(objToSave: ProgramConfigureObj): Promise<Object> {
      let promise = getSaveConfigureResponse(objToSave);
      let result = await promise; 
      return result as object;
    }
    return resultNew(objToSave);
  }

  @Mutation
  public afterProgramUpdated(payload: any) {
    this.UpdatedProgramRes = payload;
  }

  @Action({ commit: "afterProgramUpdated" })
  public UpdateProgram(payload: object) {
    return updateProgramStatus(payload).then((response) => {
      return { UpdatedProgramRes: response };
    }, (error) => {
      return { UpdatedProgramRes: error };
    });
  }


  @Mutation
  public afterProgramConfigUpdated(payload: any) {
    this.fetchUpdatedProgram = payload;
    if (this.fetchUpdatedProgram && this.fetchUpdatedProgram.data && this.fetchUpdatedProgram.data.programDto) {
      this.productId= this.fetchUpdatedProgram.data.programDto.productId;
    }
  }
  @Action({ commit: "afterProgramConfigUpdated" })
  public getProgramConfigObj(programId: number): Promise<Object> {
    async function getFetchConfigProgram(idProgram: number): Promise<Object> {
      let promise = getFetchConfigResponse(idProgram);
      let result = await promise; 
      return result as object;
    }
    return getFetchConfigProgram(programId);
  }

  @Action({ commit: "afterProgramConfigUpdated" })
  public getProgramConfigObjProgramPage(programId: number): Promise<Object> {
    async function getFetchConfigProgram(idProgram: number): Promise<Object> {
      let promise = getFetchConfigResponseProgram(idProgram);
      let result = await promise; 
      return result as object;
    }
    return getFetchConfigProgram(programId);
  }

  @Action({ commit: "afterProgramConfigUpdated" })
  public getInviteProgramConfigObj(programId: number): Promise<Object> {
    async function getFetchConfigProgram(idProgram: number): Promise<Object> {
      let promise = getFetchProgramDetails(idProgram);
      let result = await promise; 
      return result as object;
    }
    return getFetchConfigProgram(programId);
  }

  @Action({ commit: 'afterProgramConfigUpdated' })
  public getProgramDataForEdit(programId:number) {
    async function getEditProgramData(programId:number): Promise<Object> {
      let promise = getSavedProgramData(programId);
      let result = await promise; 
      return result as object;
    }
    return getEditProgramData(programId);
  }


  @Action({ commit: "afterProgramUpdated" })
  public updatedProgramWithEditedData(payload: object) {
    return updateProgramWithEditedDataAPI(payload).then((response) => {
      return { data: response };
    }, (error) => {
      return { data: error };
    });
  }



  @Action({ commit: 'afterProgramConfigUpdated' })
  public getAutoSaveProgramConfig(obj:any) {
    async function getAutoSaveConfigProgram(obj:any): Promise<Object> {
      let promise = getAutoSaveProgram(obj);
      let result = await promise; 
      return result as object;
    }
    return getAutoSaveConfigProgram(obj);
  }

  @Mutation
  public afterTasksMutate(payload: any) {
    this.firstTimeTasks = payload;
  }
  @Action({ commit: "afterTasksMutate" })
  public fetchTasks(productIdArray: any) {
    async function getFetchTasks(productIdArray: any): Promise<Object> {
      let promise = getFirstTimeTasks(productIdArray);
      let result = await promise; 

      return result as object;
    }
    return getFetchTasks(productIdArray);
  }



  @Action({ commit: "afterProgramConfigUpdated" })
  public async getProgramConfigByIdObj(programId: number): Promise<Object> {
    let result = await FetchConfigProgByIdResponse(programId);
    return result as object;
  }


  @Action({ commit: "afterConfigureMutate" })
  public editProgramConfigure(objToSave: ProgramConfigureObj): Promise<Object> {
    let resultNew = async function getSaveResponse(objToSave: ProgramConfigureObj): Promise<Object> {
      let promise = editConfigureResponse(objToSave);
      let result = await promise; 
      return result as object;
    }
    return resultNew(objToSave);
  }

  @Mutation
  public afterCreateSiteMutate(payload: any) {
    this.siteSessionRes = payload;
  }

  @Action({ commit: "afterCreateSiteMutate" })
  public createNewSite(payload: any): Promise<Object> {
    let resultNew = async function getSaveResponse(payload: any): Promise<Object> {
      let promise = saveSiteSessionDetails(payload);
      let result = await promise; 
      return result as object;
    }
    return resultNew(payload);
  }
}
export default getModule(ProgramConfigureModule);
