import { localize } from 'vee-validate';
import { addRules } from './helpers';
import { rules } from './rules';
import { en } from './locales/en';

addRules(rules);

localize({
  en: {
    messages: en,
  },
});
