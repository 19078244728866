















































import { DeleteRosterConfirmation } from "@/popupcomponents/DeleteRosterConfirmation/DeleteRosterConfirmation";
export default DeleteRosterConfirmation;
