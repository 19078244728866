import { Vue, Component, Watch } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import APP_CONST from '@/constants/AppConst';
import APP_UTILITIES from '@/utilities/commonFunctions';
import { getUserDetailsResponseObj, selectStar } from '@/services/userService/users-api';
import programListStore from '@/store/modules/programList';
import ProgramStatusDropdown from './programStatusdropdown/ProgramStatusDropdown.vue';


@Component({
    components: {
        'program-status-dropdown': ProgramStatusDropdown
    }
})
export default class ProgramList extends Vue {
    private objScreenText: ScreenText = new ScreenText();
    public selectedProgramIndex: number = 0;
    public hoverStyleObj: any = {};
    public hover: boolean = false;
    public hoverText: string = '';
    public hoverLabel: string = '';
    public currentRoleId: number = 0;
    public localConstant = APP_CONST;
    programId: number = 0;
    public filteredByStar: boolean = false;
    routeToNavigate = ['/', '/', '/', 'Partner Home', 'HomeProgAdmin', 'HomeSessionAdmin', 'HomeStaff'];
    public toggleDropDown: boolean = false;
    public showStatusDD: boolean = false;
    public programStatusDropdown: any = APP_CONST.PROGRAMS_STATUS_DROPDOWN;
    public programAdmin: number = APP_CONST.ROLE_TYPE_PROGRAM_ADMIN;
    public currentRole: number = APP_CONST.ZERO;
    public statusTwo: number = APP_CONST.TWO;

    public isNoProgram: boolean = true;

    public getScreenText(key: string): string {
        return this.objScreenText.getScreenText(key);
    }

    get programsList() {
        if (this.drilledProgram && this.drilledProgram.programName) {
            return programListStore.programAndSiteList.filter((program: any) => {
                return program.programName != this.drilledProgram.programName;
            });
        }
        return programListStore.programAndSiteList;
    }

    get drilledProgram() {
        return programListStore.drilledProgram;
    }

    get showEmptyState() {
        return programListStore.showEmptyState;
    }

    get selectedProgramStatus() {
        return programListStore.selectedProgramsStatus;
    }

    @Watch('$route', { immediate: true, deep: true })
    detechChangeInRoute(route: any) {
        /* istanbul ignore else */
        if (route.path !== '/partnerhome') {
            this.selectedProgramIndex = -1;
        }
    }

    async getProgramsList(fromDropdown?: boolean) {
        const highest_role: any = APP_UTILITIES.getCookie(APP_CONST.HIGHEST_ROLE);
        let storedAccountId: any = APP_UTILITIES.getCookie('user_role');
        let selectedProgramStatus: any = APP_UTILITIES.getCookie('selectedProgramStatus');
        let { id } = APP_UTILITIES.coreids();
        const payload = APP_CONST.ACCOUNT_ADMIN_PROGRAMS_LIST;
        if (!fromDropdown && selectedProgramStatus && !this.filteredByStar) {
            let selectedProgram = this.programStatusDropdown.find((program: any) => program.filter == selectedProgramStatus);
            programListStore.mutateProgramStatus(selectedProgram);
        }
        payload.id = JSON.parse(storedAccountId)[0].accountId;
        payload.userId = id;
        payload.filterKey = 0;

        if (highest_role == 6 || highest_role == 7) {
            payload.filterKey = APP_CONST.ONE;
        }
        let data = { programsList: [], progInRole: [], showEmptyState: false }
        programListStore.setProgramAndSiteList(data);
        programListStore.updateProgramAndSiteList(payload).then((response: any) => {
            let activeArray: any = programListStore.programListFilters;
            if (!activeArray.length || activeArray[0] == null) {
                this.isNoProgram = true;
                return;
            }
            this.isNoProgram = false;
            let active: any = activeArray.filter((ele: any) => { return ele && ele.status == 1 });
            let disable: any = activeArray.filter((ele: any) => { return ele && ele.status == 2 });
            if (!fromDropdown && !APP_UTILITIES.getCookie('selectedProgramStatus') && (active.length && disable.length) && this.selectedProgramStatus.key == APP_CONST.ZERO) {
                this.selectStatus({ name: "Active Program", filter: APP_CONST.ONE });
                return;
            } else {
                if (active.length && disable.length && payload.filterKey == 0) {
                    this.programStatusDropdown = APP_CONST.PROGRAMS_STATUS_DROPDOWN;
                }
                else if (disable.length == 0 && (payload.filterKey == 0 || payload.filterKey == 1)) {
                    this.programStatusDropdown = [

                        {
                            "name": "Active Program",
                            "filter": 1,
                        }
                    ];
                } else if (active.length == 0 && (payload.filterKey == 0 || payload.filterKey == 2)) {
                    this.programStatusDropdown = [
                        {
                            "name": "Disabled Program",
                            "filter": 2,
                        }
                    ];

                }
            }
            if (this.programStatusDropdown.length == 1) {
                programListStore.mutateProgramStatus(this.programStatusDropdown[0])
            }
            let selectedStatus: number = this.selectedProgramStatus.key;
            programListStore.mutateProgramStatus(this.programStatusDropdown[this.programStatusDropdown.findIndex((x: any) => x.filter == selectedStatus)]);
            if (this.programStatusDropdown.length > 1) {
                let programListNew = [];
                if (selectedStatus == APP_CONST.STATUS_ACTIVE) {
                    programListNew = active;
                }
                else if (selectedStatus == APP_CONST.STATUS_INACTIVE) {
                    programListNew = disable;
                }
                else {
                    programListNew = [...active, ...disable]
                }

                programListNew = programListNew.sort((a: any, b: any) => {
                    if (a.programName.toLowerCase() > b.programName.toLowerCase()) {
                        return 1;
                    }
                    else if (a.programName.toLowerCase() < b.programName.toLowerCase()) {
                        return -1;
                    }
                    else {
                        return 0;
                    }
                })
                let starred: any = [];
                let notStarred: any = [];
                programListNew.forEach((program: any) => {
                    if (program.isStar) {
                        starred.push(program)
                    }
                    else if (!program.isStar) {
                        notStarred.push(program)
                    }
                })
                programListNew = [...starred, ...notStarred]
                let programAndSiteListData = { programsList: programListNew, progInRole: this.drilledProgram, showEmptyState: false }
                programListStore.setProgramAndSiteList(programAndSiteListData);
            }

            const progId = response.programsList.length ? response.programsList[0].programId : 0;
            this.selectedProgramIndex = !this.selectedProgramIndex ? progId : this.selectedProgramIndex;
            this.checkProgramsAndSitesStar();
        });
    }

    sortByProgramName() {
        this.filteredByStar = false;
        /* istanbul ignore else */
        if (this.programsList) {
            this.programsList.sort(this.sortByProperty('programName', 'asc'));
            this.programsList.forEach((program: any) => {
                /* istanbul ignore else */
                if (program.sessionStarInfos.length) {
                    return program.sessionStarInfos.sort(this.sortByProperty('sessionName', 'asc'))
                }
            });
        }
        /* istanbul ignore else */
        if (this.drilledProgram && this.drilledProgram.sessionStarInfos.length) {
            this.drilledProgram.sessionStarInfos.sort(this.sortByProperty('sessionName', 'asc'))
        }
    }

    sortDrilledByStar() {
        /* istanbul ignore else */
        if (this.drilledProgram && this.drilledProgram.sessionStarInfos.length) {
            this.filteredByStar = true;
            this.drilledProgram.sessionStarInfos.sort(this.sortByProperty('sessionName', 'asc'))
            this.drilledProgram.sessionStarInfos.sort(this.sortByProperty('isStar', 'desc'))
        }
    }

    sortByStar(programId: number, siteId: number) {
        /* istanbul ignore else */
        if (this.programsList) {
            this.filteredByStar = true;
            this.programsList.sort(this.sortByProperty('programName', 'asc'))
            this.programsList.sort(this.sortByProperty('isStar', 'desc'));
            this.programsList.forEach((program: any) => {
                /* istanbul ignore else */
                if (program.sessionStarInfos.length) {
                    program.sessionStarInfos.sort(this.sortByProperty('sessionName', 'asc'))
                }
            });
            this.programsList.forEach((program: any) => {
                /* istanbul ignore else */
                if (program.sessionStarInfos.length) {
                    program.sessionStarInfos.sort(this.sortByProperty('isStar', 'desc'))
                }
            });
        }
        this.sortDrilledByStar();
        /* istanbul ignore else */
        if (programId) {
            let { id } = APP_UTILITIES.coreids();
            let payload = {
                siteId: siteId,
                programId: programId,
                userId: id
            };

            selectStar(payload).then(() => {
                this.getProgramsList();
            });
        }
    }

    openDropdown() {
        if (this.programStatusDropdown.length == 1) {
            return
        } else {
            this.showStatusDD = !this.showStatusDD
        }
    }

    toggleProgramTab(index: number) {
        /* istanbul ignore else */
        if (this.selectedProgramIndex == index) {
            this.selectedProgramIndex = -1;
        } else {
            this.selectedProgramIndex = index;
        }
        if (this.programsList.length && this.programsList[this.programsList.length - 1].programId == index) {
            setTimeout(() => {
                let elem: any = document.getElementById(`accordion-container`);
                elem.scrollTop += 50;
            }, 100)
        }
    }

    sortByProperty(property: any, order: string) {
        return function (a: any, b: any) {
            if (order === APP_CONST.ORDER_ASC_TEXT) {
                if (String(a[property]).toLowerCase() > String(b[property]).toLowerCase())
                    return 1;
                else if (String(a[property]).toLowerCase() < String(b[property]).toLowerCase())
                    return -1;
            } else {
                if (String(a[property]).toLowerCase() < String(b[property]).toLowerCase())
                    return 1;
                else if (String(a[property]).toLowerCase() > String(b[property]).toLowerCase())
                    return -1;
            }

            return 0;
        }
    }

    navigateToRole(progId: number, status?: number, siteId?: number) {
        this.clearProgramFromLocal();
        const role = APP_UTILITIES.getCookie("user_role");
        const parsedRole = role && JSON.parse(role);
        const [roleTomodify] = parsedRole;
        const superDrill = APP_UTILITIES.getCookie('super_user_drilldown');
        APP_UTILITIES.setCookie("selectedProgramStatus", JSON.stringify(status), 1);
        let statusObj = {
            name: '',
            filter: status
        };
        switch (status) {
            case 0:
                statusObj.name = 'All Program'
                break;
            case 1:
                statusObj.name = 'Active Program'
                break;
            case 2:
                statusObj.name = 'Disabled Program'
                break;
            default:
                statusObj.name = 'All Program'
                break;
        }
        programListStore.mutateProgramStatus(statusObj);
        if (Boolean(superDrill)) {
            roleTomodify.roleId = (siteId && APP_CONST.ROLE_TYPE_SESSION_ADMIN) || APP_CONST.ROLE_TYPE_PROGRAM_ADMIN;
            roleTomodify.programId = progId;
            roleTomodify.siteId = siteId || 0;
            APP_UTILITIES.setCookie("user_role", JSON.stringify([roleTomodify]), 1);
            APP_UTILITIES.setCookie("programId", JSON.stringify(roleTomodify.programId), 1);
            APP_UTILITIES.setCookie("siteId", JSON.stringify(roleTomodify.siteId), 1);
            this.getProgramsList(true);
            if (this.$route.path !== '/home') {
                this.$router.replace({ name: `${this.routeToNavigate[roleTomodify.roleId - 1]}` });
            } else {
                this.$emit('loadNav');
            }
            return
        }
        const userId = APP_UTILITIES.getCookie(APP_CONST.USER_ID);
        const parsedUser = Number(userId);
        /* istanbul ignore else */
        if (!parsedUser) {
            return
        }
        /* istanbul ignore next */
        getUserDetailsResponseObj(parsedUser).then((response) => {
            if (response.status === APP_CONST.RESPONSE_200) {
                const userDt = response.data;
                let roleToAdd = userDt.userRoles.find((role: { id: number, roleId: number, accountId: number, programId: number, siteId: number }) => {
                    return (role.programId === progId && (siteId ? role.siteId === siteId : true));
                });
                if (!roleToAdd) {
                    roleToAdd = userDt.userRoles.find((role: { id: number, roleId: number, accountId: number, programId: number, siteId: number }) => {
                        return (role.programId === progId);
                    });
                }
                if (!roleToAdd) {
                    roleToAdd = userDt.userRoles.find((role: { id: number, roleId: number, accountId: number, programId: number, siteId: number }) => {
                        return (role.accountId == roleTomodify.accountId);
                    });
                }
                roleTomodify.roleId = roleToAdd && roleToAdd.roleId;
                roleTomodify.programId = (roleToAdd && roleToAdd.programId) || (progId || 0);
                roleTomodify.siteId = (roleToAdd && roleToAdd.siteId) || (siteId || 0);
                if (roleTomodify.programId && roleToAdd.roleId === 4) {
                    roleTomodify.roleId = 5;
                }
                if (roleTomodify.siteId && (roleToAdd.roleId === 4 || roleToAdd.roleId === 5)) {
                    roleTomodify.roleId = 6;
                }
                APP_UTILITIES.setCookie("user_role", JSON.stringify([roleTomodify]), 1);
                APP_UTILITIES.setCookie("programId", JSON.stringify(roleTomodify.programId), 1);
                APP_UTILITIES.setCookie("siteId", JSON.stringify(roleTomodify.siteId), 1);
                this.getProgramsList();
                if (this.$route.path !== '/home') {
                    this.$router.replace({ name: `${this.routeToNavigate[roleTomodify.roleId - 1]}` });
                } else {
                    this.$emit('loadNav');
                }
            }
        });
    }

    clearProgramFromLocal() {
        APP_UTILITIES.deleteFromLocalStorage('enrollmentselectedprogram');
        APP_UTILITIES.deleteFromLocalStorage('retentionselectedprogram');
        APP_UTILITIES.deleteFromLocalStorage('attendanceselectedprogram');
        APP_UTILITIES.deleteFromLocalStorage('applicationselectedprogram');
        APP_UTILITIES.deleteFromLocalStorage('qualityselectedprogram');
    }

    showTooltip(val: string, $event: any, id: string, isMobileView: string) {
        this.hover = true;
        this.hoverText = val;
        this.hoverLabel = id;
        const boundBox = $event && $event.target.getBoundingClientRect();
        const coordX = boundBox.left;
        const coordY = boundBox.top;
        this.hoverStyleObj = {
            top: (coordY + 40).toString() + "px",
            left: (coordX + 50).toString() + "px",
            width: "fit-content;",
            'text-transform': 'none'
        }
    }

    hideTooltip() {
        this.hover = false;
        this.hoverText = '';
        this.hoverLabel = '';
        this.hoverStyleObj = {};
    }

    checkProgramsAndSitesStar() {
        for (const program of this.programsList) {
            /* istanbul ignore else */
            if (program.isStar) {
                this.filteredByStar = true;
                break;
            }
        }
    }

    mounted() {
        let selectedProgramStatus: any = APP_UTILITIES.getCookie('selectedProgramStatus') || 0;
        let statusObj = {
            name: '',
            filter: Number(selectedProgramStatus)
        };
        switch (selectedProgramStatus) {
            case 0:
                statusObj.name = 'All Program'
                break;
            case 1:
                statusObj.name = 'Active Program'
                break;
            case 2:
                statusObj.name = 'Disabled Program'
                break;
            default:
                statusObj.name = 'All Program'
                break;
        }

        programListStore.mutateProgramStatus(statusObj);
        this.getProgramsList();
        const { roleId, programId } = APP_UTILITIES.coreids();
        this.currentRoleId = roleId;
        this.programId = programId;
        let userRoles: any = APP_UTILITIES.getCookie("highest_role");
        let userData = userRoles && JSON.parse(userRoles);
        this.currentRole = userData;
    }

    toggleDrop() {
        this.toggleDropDown = !this.toggleDropDown
    }

    selectStatus(programStatus: any) {
        programListStore.mutateProgramStatus(programStatus);
        this.getProgramsList(true);
        this.closeStatusDropdown();
    }

    closeStatusDropdown() {
        this.showStatusDD = false;
    }

    get noSiteText() {
        return this.getScreenText('NO_SITE');
    }
}