import {
  LabelData,
  LabelType
} from '@/commoncomponents/CmsLabelComponent/CmsLabelComponent';
import APP_CONST from '@/constants/AppConst';
import { AxiosResponse } from 'axios';
import APP_UTILITIES from './commonFunctions';

export enum CourseStatus {
  IN_PROGRESS = "started",
  COMPLETE = "completed",
  TO_DO = "not-started"
}

export enum ContentType {
  COURSE = "COURSE",
  ACTIVITY = "ACTIVITY",
  STRATEGY = "STRATEGY",
  RESOURCE = "RESOURCE",
  ROUTINE = "ROUTINE",
  VIDEO = "VIDEO",
  LEARN_ABOUT = "LEARN_ABOUT",
  CONTENT_PREVIEW = "CONTENT_PREVIEW"
}

export type ContentCardData = {
  title: string;
  imageUrl?: string;
  description?: string;
  timeDuration?: string;
  courseStatus?: CourseStatus;
  contentType?: string;
  id?: string;
  activityType?: ActivityType;
  tiCourseIds?: Array<String>;
  subscriptionCategory?: Array<String>;
  searchPriority?: string;
  redirectUrl?: string;
  resourceType?: string;
};

export type VideoCardData = ContentCardData & {
  videoUrl?: string;
};

export type CollectionCardData = {
  collectionId: string;
  title: string;
  totalItems: number;
  isLocked: boolean | null;
  contentImages?: Array<string>;
  items?: string[];
};

export interface LearningLibraryCollection {
  collectionId: number;
  name: string;
  items: ContentCardData[];
  totalItems: number;
  isLocked: boolean;
  contentImages?: string[];
}

export enum ActivityType {
  OLD = "activity",
  THEME = "activityTheme",
  CONNECTIONS = "activityConnections",
  DISCUSSIONS = "activityDiscussions",
  LIFE_SKILLS = "activityLifeSkills",
  FITNESS = "activityFitness",
  BLAST = "activityBlast",
  SERVICE_LEARNING = "activityServiceLearning"
}

export enum MediaResourceType {
  MEDIA = "MediaResource",
  MEDIA_VIDEO = "mediaResourceVideo",
  MEDIA_VIDEO_CAPITALIZED = "MediaResourceVideo", // Algolia returns this as mediaResourceVideo vs contentful as MediaResourceVideo
  MEDIA_LONG = "MediaResourceLong"
}

export const checkCMSEnabledReRoute = (appSetting: any) => {
  const highestRole = Number(APP_UTILITIES.getCookie('highest_role'));
  const isBxl = highestRole !== null && highestRole < APP_CONST.FOUR;
  if (isBxl) {
    //Check if previewing
    const drilldrown: any = APP_UTILITIES.getCookie(
      APP_CONST.SUPER_USER_DRILLDOWN
    );
    if (drilldrown === "true") {
      //BXL Admin Previewing Account, process as if a normal user.
    } else {
      //Logged in normally as an admin user, show CMS
      return false;
    }
  }
  const { isLoading, lx } = appSetting;
  if (isLoading) {
    //Do Nothing
    return false;
  } else {
    if (lx === false) {
      return true;
    }
  }
};

export const trimGraphQuery = (str: string) => {
  // Contentful has a GQL Query size limit of 8192 Bytes for cacheing reasons
  // https://www.contentfulcommunity.com/t/graphql-api-query-size/3443
  return str.replace(/\s\s+/g, ' ');
};

export const stringToActvityType = (str: any) => {
  let result = ActivityType.OLD;
 const strLowerCase = String(str).toLowerCase();
 const activityTypesLowerCased = Object.values(ActivityType).map(value => value.toLowerCase());
 const index = activityTypesLowerCased.indexOf(strLowerCase);

 if (index !== -1) {
   result = Object.values(ActivityType)[index] as ActivityType;
 }
 return result;
};

export const relatedActivityType = (item: any) => {
  const typeString = item['__typename'];
  const contentType = stringToContentType(typeString);
  if (contentType === ContentType.ACTIVITY) {
    if (typeString === "Activity") {
      return ActivityType.OLD;
    }
    return typeString.charAt(0).toLowerCase() + typeString.slice(1);
  }
  else {
    return undefined;
  }
};

export const activityTypeToSubtype = (type: ActivityType) => {
  switch (type) {
    case ActivityType.THEME:
      return '';
    case ActivityType.CONNECTIONS:
      return 'Connections';
    case ActivityType.DISCUSSIONS:
      return 'Discussions';
    case ActivityType.LIFE_SKILLS:
      return 'Life Skills';
    case ActivityType.SERVICE_LEARNING:
      return 'Service Learning';
    case ActivityType.FITNESS:
    case ActivityType.BLAST:
      return 'Fitness';
    default:
      return '';
  }
};


export const stringToContentType: (str: string) => ContentType = (
  str: string
) => {
  let result = ContentType.COURSE;
  const contentfulStrings: Record<ContentType, any> = {
    [ContentType.ACTIVITY]: {
      strings: [
        "Activity",
        "activityTheme",
        "activityDiscussions",
        "activityConnections",
        "activityLifeSkills",
        "activityServiceLearning",
        "activityFitness",
        "activityBlast",
        // The casing varies between GQL and Algolia results :/
        "ActivityTheme",
        "ActivityDiscussions",
        "ActivityConnections",
        "ActivityLifeSkills",
        "ActivityServiceLearning",
        "ActivityFitness",
        "ActivityBlast"
      ]
    },
    [ContentType.COURSE]: {
      strings: ['Course']
    },
    [ContentType.STRATEGY]: {
      strings: ['Strategy']
    },
    [ContentType.RESOURCE]: {
      strings: [
        'MediaResource',
        'MediaResourceVideo',
        'mediaResourceVideo',
        'MediaResourceLong'
      ]
    },
    [ContentType.ROUTINE]: {
      strings: ['Routine']
    },
    [ContentType.VIDEO]: {
      strings: ['Video']
    },
    [ContentType.LEARN_ABOUT]: {
      strings: ['learnAbout', 'LearnAbout']
    },
    [ContentType.CONTENT_PREVIEW]: {
      strings: ['contentPreview', 'ContentPreview']
    }
  };

  Object.keys(contentfulStrings).forEach(key => {
    const typedkey = key as keyof typeof ContentType;
    if (contentfulStrings[typedkey].strings.includes(str)) {
      result = ContentType[typedkey];
    }
  });

  return result;
};

export const getCopyrightMessage = () => {
  const year = new Date().getFullYear();
  return `Copyright ${year}, The B.E.L.L. Foundation, Inc. All Rights Reserved`;
};

export const buildRelatedLinks = (arr: Array<any>) => {
  const result: Array<LinkData> = [];
  arr.forEach(item => {
    //Some other content type
    const baseURL = window.location.href;
    const regex = /(.*)\/resources\/.*\?/;
    const search = baseURL.match(regex) || ([] as any);
    // eslint-disable-next-line
    const rootUrl = search.at(1);

    const type: ContentType = stringToContentType(item.__typename);
    switch (type) {
      case ContentType.ACTIVITY:
        result.push({
          label: item.itemTitle,
          value: `${rootUrl}${APP_CONST.APP_ROUTES.CMS_ACTIVITY_LANDING.path
            }?id=${item.sys.id}&activityType=${stringToActvityType(
              item["__typename"]
            )}`
        });
        break;
      case ContentType.COURSE:
        result.push({
          label: item.itemTitle,
          value: `${rootUrl}${APP_CONST.APP_ROUTES.CMS_COURSE_LANDING.path}?id=${item.sys.id}`
        });
        break;
      case ContentType.STRATEGY:
        result.push({
          label: item.itemTitle,
          value: `${rootUrl}${APP_CONST.APP_ROUTES.CMS_STRATEGY_LANDING.path}?id=${item.sys.id}`
        });
        break;
      case ContentType.ROUTINE:
        result.push({
          label: item.itemTitle,
          value: `${rootUrl}${APP_CONST.APP_ROUTES.CMS_ROUTINE_LANDING.path}?id=${item.sys.id}`
        });
        break;
      case ContentType.RESOURCE:
        result.push({
          label: item.itemTitle,
          value: `${rootUrl}${APP_CONST.APP_ROUTES.CMS_MEDIA_RESOURCE_LANDING.path}?id=${item.sys.id}`
        });
        break;
      case ContentType.LEARN_ABOUT:
        result.push({
          label: item.itemTitle,
          value: `${rootUrl}${APP_CONST.APP_ROUTES.CMS_LEARN_ABOUT_LANDING.path}?id=${item.sys.id}`
        });
        break;
      case ContentType.CONTENT_PREVIEW:
        result.push({
          label: item.itemTitle,
          value: `${rootUrl}${APP_CONST.APP_ROUTES.CMS_CONTENT_PREVIEW_LANDING.path}?id=${item.sys.id}`
        });
        break;
      default:
        break;
    }
  });

  return result;
};

export const contenttypeToString: (str: ContentType) => string = (
  type: ContentType
) => {
  switch (type) {
    case ContentType.ACTIVITY:
      return 'Activity';
    case ContentType.COURSE:
      return 'Course';
    case ContentType.LEARN_ABOUT:
      return 'Learn About';
      case ContentType.CONTENT_PREVIEW:
        return 'Content Preview';
    case ContentType.STRATEGY:
      return 'Strategy';
    case ContentType.RESOURCE:
      return 'Resource';
    case ContentType.ROUTINE:
      return 'Routine';
    case ContentType.VIDEO:
      return 'Video';
    default:
      return '';
  }
};

export const getAudienceArray = (audienceCollection: any | undefined) => {
  const result =
    (audienceCollection &&
      audienceCollection.items &&
      audienceCollection.items.map((audItem: any) => audItem.itemTitle)) ||
    [];
  return result;
};

export const getPrintables = (collection: any | undefined) => {
  return (collection &&
    collection.items.length
    ? collection.items.map((item: any) => ({
      label: (item && item.fileName) || '',
      value: (item && item.url) || ''
    }))
    : undefined)
}

export const isAuthorizedContent = (
  pageData:
    | CoursePageData
    | MediaResourcePageData
    | StrategyPageData
    | RoutinePageData
    | ActivityPageData
    | LearnAboutPageData
    | ContentPreviewPageData
) => {
  const audienceCollection = pageData.audienceCollection || [];

  const highestRole = Number(APP_UTILITIES.getCookie('highest_role'));
  const isAdmin = highestRole !== null && highestRole < APP_CONST.SEVEN;

  if (!audienceCollection || audienceCollection.length < 1) {
    // Audience Colleciton Empty, not authorized
    return false;
  }

  return isAdmin || audienceCollection.includes('Staff');
};

export const minsToString = (num: number | undefined) => {
  if (!num) {
    return '';
  }
  const minutes = num % 60;
  const hours = Math.floor(num / 60);
  let minString = '';
  let hourString = '';
  if (hours > 0) {
    hourString = `${hours} ${hours > 1
      ? 'hrs'
      : 'hr'}`;
  }
  if (minutes > 0) {
    minString = `${minutes} min`;
  }
  return hours > 0
    ? `${hourString} ${minString}`
    : minString;
};

export type LinkData = {
  label: string;
  value: string;
};

export type PageData = {
  audienceCollection: Array<String>;
};

export type CoursePageData = {
  title: string;
  courseId: string;
  heroImage: string;
  objectivesList: Array<string>;
  courseDescription: string;
  courseTagList: Array<string>;
  labelData: Array<LabelData>;
  contentfulDescriptionObj: any;
  navLinks: Array<LinkData>;
  resourceLinks: Array<LinkData>;
  relatedContentCards: Array<ContentCardData>;
  courseUrl: string;
  feedbackUrl?: string;
  subscriptionCategories?: Array<string>;
  curriculumLinkPath?: string;
  audienceCollection?: Array<String>;
  tiCourseIds?: Array<String>;
};

export type VideoContentData = {
  itemTitle: string;
  itemShortDescription: string;
  completionTimeMinutes: number;
  completionTimeSeconds: number;
  grades?: Array<string>;
  tags?: Array<string>;
  itemDescription?: string;
};

export type RoutinePageData = {
  title: string;
  id: string;
  heroImage: string;
  objectivesList: Array<string>;
  courseDescription: string;
  tagList: Array<string>;
  labelData: Array<LabelData>;
  itemDescription: string;
  exploreContentfulObj: any;
  reflectContentfulObj: any;
  navLinks: Array<LinkData>;
  resourceLinks: Array<LinkData>;
  relatedContentCards: Array<ContentCardData>;
  url: string;
  why: string;
  audienceCollection?: Array<String>;
};

export type StrategyPageData = {
  title: string;
  id: string;
  heroImage: string;
  objectivesList: Array<string>;
  courseDescription: string;
  tagList: Array<string>;
  labelData: Array<LabelData>;
  itemDescription: string;
  exploreContentfulObj: any;
  reflectContentfulObj: any;
  navLinks: Array<LinkData>;
  resourceLinks: Array<LinkData>;
  relatedContentCards: Array<ContentCardData>;
  url: string;
  why: string;
  audienceCollection?: Array<String>;
};

export type MediaResourcePageData = {
  title: string;
  id: string;
  heroImage: string;
  objectivesList: Array<string>;
  tagList: Array<string>;
  labelData: Array<LabelData>;
  itemDescription: string;
  navLinks: Array<LinkData>;
  relatedContentCards: Array<ContentCardData>;
  url: string | undefined;
  fileUrl: string | undefined;
  allowDownload: boolean;
  audienceCollection?: Array<String>;
  printables?: Array<LinkData>;
  resourcesBodyContentfulObj?: any;
};

export type PDFViewPageData = {
  title: string;
  id: string;
  fileUrl: string;
  allowDownload: boolean;
  contentType: string;
};

export type ActivityPageData = {
  title: string;
  id: string;
  heroImage: string;
  activityType: ActivityType;
  objectivesList: Array<string>;
  tagList: Array<string>;
  labelData: Array<LabelData>;
  itemDescription: string;
  itemTagline?: string;
  navLinks: Array<LinkData>;
  relatedContentCards: Array<ContentCardData>;
  resourceLinks?: Array<LinkData>;
  url: string | undefined;
  feedbackUrl?: string;
  lifeSkillsList?: Array<String>;
  shapeStandardsList?: Array<String>;
  fitnessFocusList?: Array<String>;
  activatesList?: Array<String>;
  functionalMovementsList?: Array<String>;
  materialsEquipmentTeacherList ?: Array<String>;
  materialsEquipmentKidsList  ?: Array<String>;
  setupTeacherTxt?: string;
  modificationsTxt?: string;
  blastDescription?: string;
  activityPartOne?: string;
  activityPartOneTime?: string;

  activityPartTwo?: string;
  activityTimePartTwoTime?: string;

  activityPartThree?: string;
  activityPartThreeTime?: string;

  activityPartFour?: string;
  activityPartFourTime?: string;

  leadershipSkills?: any;
  beforeYouStartTitle?: string;
  beforeYouStartTextObj?: any;
  academicConnectionsList?: Array<String>;
  academicConnectionsNotes?: Array<String>;
  setupContentfulObj?: any;
  setupTime?: string;
  materialsTeacher?: Array<String>;
  materialsStudent?: Array<String>;
  printables?: Array<LinkData>;
  activityOneContentfulObj?: any;
  technicallyOneContentfulObj?: any;
  activityTwoContentfulObj?: any;
  technicallyTwoContentfulObj?: any;
  lifeSkillsOneContentfulObj?: any;
  activityThreeContentfulObj?: any;
  technicallyThreeContentfulObj?: any;
  lifeSkillsTwoContentfulObj?: any;
  gradeRangeContentfulObj?: any;
  gradeRangeWhatElseContentfulObj?: any;
  springboardOneContentfulObj?: any;
  springboardTwoContentfulObj?: any;
  springboardThreeContentfulObj?: any;
  springboardFourContentfulObj?: any;
  activityBanner?: string;
  audienceCollection?: Array<String>;
  gameDirections?: string;
  gameStepsContentfulObj?: any;
  demonstrationVideos?: VideoCardData[];
  relatedBlastsVideos?: Array<ContentCardData>;
};

export type LearnAboutPageData = {
  title: string;
  id: string;
  heroImage: string;
  itemDescription?: string;
  itemShortDescription?: string;
  audienceCollection?: string[];
  learnAboutContent: any;
  relatedContent: any;
  tags?: string[];
};

export type ContentPreviewPageData = {
  title: string;
  id: string;
  heroImage: string;
  itemDescription?: string;
  itemShortDescription?: string;
  labelData: Array<LabelData>;
  releaseDate?: string;
  audienceCollection?: string[];
  marketingContent: any;
  relatedContent: any;
  tags?: string[];
};

export type ChipLabel = {
  isTag?: boolean;
  label: string;
  facet: string;
};

export type CatalogPageData = {
  facets: any;
  selectedFacets: any;
  results: Array<ContentCardData>;
  currentItems: Array<ContentCardData>;
  currentChips: Array<ChipLabel>;
  filterString: string;
  hasFilters: boolean;
  params: String;
  filtersOpen: boolean;
  queryFacets: Array<any>;
  constantFacets: Array<any>;
  loading: boolean;
  searchQuery: string;
  currentPage: number;
  totalPages: number;
  totalItems: number;
};

export type AlgoliaHitObject = {
  contentfulEnvironment: string;
  contentType: string;
  contentTitle: string;
  contentDescription: string;
  grades: Array<string>;
  activityType: string; //"Theme-tastic Adventures"
  activityTheme: Array<string>;
  minCompletionTime?: number;
  maxCompletionTime?: number;
  completionTime?: number;
  tags: Array<string>;
  audiences: Array<string>;
  subscriptions: Array<string>;
  subscriptionCategories: Array<string>;
  courseRank: string;
  programQualityDomains: Array<string>;
  contentImageUrl: string;
  objectID: string;
  _highlightResult: {
    contentTitle: {
      value: string;
      matchLevel: string;
      fullyHighlighted: boolean;
      matchedWords: Array<string>;
    };
    contentDescription: {
      value: string;
      matchLevel: string;
      matchedWords: Array<string>;
    };
  };
  thoughtIndustriesCourseIds?: Array<String>;
  timeRange?: string;
};

export type AlgoliaFacetValue = {
  value: string;
  selected: boolean;
  count: number;
};

export interface AlgoliaLxFacets {
  audiences: {
    open: boolean;
    values: Array<AlgoliaFacetValue>;
  };
  subscriptionCategories: {
    open: boolean;
    values: Array<AlgoliaFacetValue>;
  };
  subjects: {
    open: boolean;
    values: Array<AlgoliaFacetValue>;
  };
  contentTypeFacetDisplay: {
    open: boolean;
    values: Array<AlgoliaFacetValue>;
  };
  resourceType: {
    open: boolean;
    values: Array<AlgoliaFacetValue>;
  };
  activityType: {
    open: boolean;
    values: Array<AlgoliaFacetValue>;
  };
  activityThemes: {
    open: boolean;
    values: Array<AlgoliaFacetValue>;
  };
  grades: {
    open: boolean;
    values: Array<AlgoliaFacetValue>;
  };
  timeRange: {
    open: boolean;
    values: Array<AlgoliaFacetValue>;
  };
  programQualityDomains: {
    open: boolean;
    values: Array<AlgoliaFacetValue>;
  };
  tags: {
    open: boolean;
    values: Array<AlgoliaFacetValue>;
  };
}

/**
 * This is the type of data we get back from the GraphQL search
 */
export type ContentfulRelatedContent = {
  __typename: string;
  sys: {
    id: string;
  };
  itemTitle: string;
  itemThumbnailLogo: { url: string } | null;
  completionTime?: number;
  setupTime?: number;
  minCompletionTime?: number;
  maxCompletionTime?: number;
  itemShortDescription: string;
  tiCourseIds?: Array<String>;
  subCategory?: Array<String>;
  searchPriority: string;
};

export const getTimeString = (item: ContentfulRelatedContent) => {
  const type = stringToContentType(item.__typename);
  if (type === ContentType.COURSE) {
    return `${minsToString(item.minCompletionTime)} - ${minsToString(
      item.maxCompletionTime
    )}`;
  }
  else if (type === ContentType.ACTIVITY) {
    return `${minsToString(item.completionTime)}`;
  }
  return '';
};

export const relatedContentToCardData = (
  arr: Array<ContentfulRelatedContent>
) => {
  // RM undefined, null, empty items in card array
  // Contentful returns null for unpublished items
  const filteredArr = arr.filter(item => !!item);
  let result: Array<ContentCardData> = [];
  result = filteredArr.map(item => {
    const imageUrl =
      (item.itemThumbnailLogo && item.itemThumbnailLogo.url) || undefined;
    const card: ContentCardData = {
      id: item.sys.id,
      title: item.itemTitle,
      imageUrl: imageUrl,
      contentType: stringToContentType(item["__typename"]),
      description: item.itemShortDescription,
      timeDuration: getTimeString(item),
      activityType: relatedActivityType(item),
      tiCourseIds: item.tiCourseIds,
      subscriptionCategory: item.subCategory,
      searchPriority: item.searchPriority || "",
      resourceType: item["__typename"]
    };
    return card;

  });

  return result;
};

export const algoliaToCardData = (arr: Array<AlgoliaHitObject>) => {
  let result: Array<ContentCardData> = [];

  const relatedArr = arr.map(item => {
    //Algolia puts completionTime into both max and min;
    const isOneTime = item.minCompletionTime === item.maxCompletionTime;
    const relatedContent: ContentfulRelatedContent = {
      __typename: item.contentType,
      sys: {
        id: item.objectID
      },
      itemTitle: item.contentTitle,
      itemThumbnailLogo: { url: item.contentImageUrl },
      itemShortDescription: item.contentDescription,
      minCompletionTime: isOneTime ? undefined : item.minCompletionTime,
      maxCompletionTime: isOneTime ? undefined : item.maxCompletionTime,
      completionTime: isOneTime ? item.minCompletionTime : undefined,
      tiCourseIds: item.thoughtIndustriesCourseIds
        ? item.thoughtIndustriesCourseIds
        : [],
      subCategory: item.subscriptionCategories,
      searchPriority: item.courseRank
    };
    return relatedContent;
  });

  result = relatedContentToCardData(relatedArr);
  return result;
};

export const algoliaFacets = (facets: any, selectedFacets: any) => {
  const result: AlgoliaLxFacets = {
    audiences: {
      open: true,
      values: 'audiences' in facets
        ? algoliaFacet(facets.audiences)
        : []
    },
    subscriptionCategories: {
      open: true,
      values:
        'subscriptionCategories' in facets
          ? algoliaFacet(facets.subscriptionCategories)
          : []
    },
    subjects: {
      open: true,
      values: 'subjects' in facets
        ? algoliaFacet(facets.subjects)
        : []
    },
    contentTypeFacetDisplay: {
      open: true,
      values:
        'contentType' in facets
          ? algoliaFacet(facets.contentTypeFacetDisplay)
          : []
    },
    resourceType: {
      open: true,
      values: 'resourceType' in facets
        ? algoliaFacet(facets.resourceType)
        : []
    },
    activityType: {
      open: true,
      values: 'activityType' in facets
        ? algoliaFacet(facets.activityType)
        : []
    },
    activityThemes: {
      open: true,
      values:
        'activityThemes' in facets
          ? algoliaFacet(facets.activityThemes)
          : []
    },
    grades: {
      open: true,
      values: 'grades' in facets
        ? algoliaFacet(facets.grades)
        : []
    },
    timeRange: {
      open: true,
      values:
        'minCompletionTime' in facets
          ? algoliaFacet(facets.timeRange)
          : []
    },
    programQualityDomains: {
      open: true,
      values:
        'programQualityDomains' in facets
          ? algoliaFacet(facets.programQualityDomains)
          : []
    },
    tags: {
      open: true,
      values: 'tags' in facets
        ? algoliaFacet(facets.tags)
        : []
    }
  };
  // Re-order grades
  const gradeOrderArray = [
    'Pre',
    'PK',
    'K',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8'
  ];
  result.grades.values.sort((a, b) => {
    return gradeOrderArray.indexOf(a.value) - gradeOrderArray.indexOf(b.value);
  });
  // Re order time range
  const timeRangeOrderArray = [
    '< 15min',
    '16 - 30min',
    '31 - 60min',
    '> 60min'
  ];
  result.timeRange.values.sort((a, b) => {
    return (
      timeRangeOrderArray.indexOf(a.value)
      - timeRangeOrderArray.indexOf(b.value)
    );
  });
  // Update selected values based on selected facets
  selectedFacets.forEach((facet: any) => {
    result[facet.facet as keyof AlgoliaLxFacets].values.forEach(
      (value: any) => {
        if (value.value === facet.label) {
          value.selected = true;
        }
      }
    );
  });
  return result;
};

export const algoliaFacet = (facet: any) => {
  const result: Array<AlgoliaFacetValue> = Object.keys(facet).map(key => {
    return {
      value: key,
      selected: false,
      count: facet[key]
    };
  });

  return result;
};

export type LabelRequest = {
  type: ContentType;
  additionalType?: string;
  minCompletionTime?: number;
  maxCompletionTime?: number;
  videoTime?: string;
  time?: number;
  numberOfKids?: number;
  grades?: Array<String>;
  credit?: number;
  date?: string;
};


export function formatDate(dateString: any): string {
  const months: string[] = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const date: Date = new Date(dateString);
  const year: number = date.getFullYear();
  const month: string = months[date.getMonth()];
  const day: number = date.getDate();
  return `${month}. ${day}, ${year}`;
}

export const gqlToLabelData = (res: LabelRequest) => {
  const labels: Array<LabelData> = [];
  Object.keys(res).map(key => {
    if (!res[key as keyof LabelRequest]) {
      return;
    }
    switch (key) {
      case 'type':
        if (res.type !== ContentType.COURSE) {
          if (res.type === ContentType.ACTIVITY) {
            labels.push({
              title: 'Type:',
              type: LabelType.TYPE,
              value: `${contenttypeToString(res.type)}${res.additionalType}`
            });
          }
          else {
            labels.push({
              title: 'Type:',
              type: LabelType.TYPE,
              value: contenttypeToString(res.type)
            });
          }
        }
        break;
      case 'minCompletionTime':
        if (res.maxCompletionTime) {
          const length = `${minsToString(
            res.minCompletionTime
          )} - ${minsToString(res.maxCompletionTime)}`;
          labels.push({ title: 'Time:', type: LabelType.TIME, value: length });
        }
        break;
      case 'time':
        labels.push({
          title: 'Time:',
          type: LabelType.TIME,
          value: minsToString(res.time)
        });
        break;
      case 'numberOfKids':
        labels.push({
          title: 'Number of Kids:',
          type: LabelType.NUMBER_OF_KIDS,
          value: `${res.numberOfKids}`
        });
        break;
      case 'grades':
        // eslint-disable-next-line no-case-declarations
        const gradeList: Array<String> = res.grades || [];
        labels.push({
          title: 'Grade Range:',
          type: LabelType.GRADE_RANGE,
          value: gradeList.join(', ')
        });
        break;
      case 'credit':
        labels.push({
          title: 'Clock Hours:',
          type: LabelType.CLOCK_HOURS,
          value: `${minsToString(res.credit)}`
        });
        break;
      case 'videoTime':
        labels.push({
          title: 'Time',
          type: LabelType.TIME,
          value: `${res.videoTime}`
        });
        break;
      case 'date':
        labels.push({
          title: 'Release Date:',
          type: LabelType.DATE,
          value: `${formatDate(res.date)}`
        });
        break;
      default:
        break;
    }
  });
  labels.sort((a, b) => {
    return a.type - b.type;
  });

  return labels;
};

export const courseResponseToData = (res: AxiosResponse<any>) => {
  const courseData = res.data.data.course;
  const courseType = stringToContentType(courseData.__typename);
  const data: CoursePageData = {
    title: courseData.itemTitle,
    courseId: courseData.sys.id,
    heroImage: courseData.itemHeroImage && courseData.itemHeroImage.url,
    objectivesList: courseData.objectives,
    courseDescription: courseData.itemShortDescription,
    courseTagList: (courseData && courseData.tags) || undefined,
    feedbackUrl: courseData.surveyLink,
    labelData: gqlToLabelData({
      type: courseType,
      minCompletionTime: courseData.minCompletionTime || undefined,
      maxCompletionTime: courseData.maxCompletionTime || undefined,
      numberOfKids: courseData.numberOfKids || undefined,
      grades: courseData.grades || undefined,
      credit: courseData.credit || undefined
    }),
    contentfulDescriptionObj: courseData.itemDescription,
    navLinks: [
      { label: 'Some Link', value: 'https://google.com' },
      { label: courseData.itemTitle, value: '' }
    ],
    resourceLinks: [],
    relatedContentCards: [],
    courseUrl: courseData.courseUrl,
    subscriptionCategories: [],
    curriculumLinkPath: '',
    audienceCollection: getAudienceArray(courseData.audienceCollection),
    tiCourseIds: (courseData && courseData.thoughtIndustriesCourseIds) || []
  };
  if (
    courseData.subscriptionCollection &&
    courseData.subscriptionCollection.items.length > 0
  ) {
    const subscriptionsArray = courseData.subscriptionCollection.items.map(
      (subscription: any) => subscription.subscriptionCategory
    );
    data.subscriptionCategories = subscriptionsArray
      .flat()
      .filter(
        (value: string, index: number, self: any) =>
          self.indexOf(value) === index
      );
    if (
      data.subscriptionCategories &&
      data.subscriptionCategories.includes('Enrichment')
    ) {
      data.curriculumLinkPath = 'Enrichment';
    }
    else if (
      data.subscriptionCategories &&
      data.subscriptionCategories.includes('Academic')
    ) {
      data.curriculumLinkPath = 'Academic';
    }
  }
  if (
    courseData.relatedResourcesCollection &&
    courseData.relatedResourcesCollection.items
  ) {
    data.resourceLinks = buildRelatedLinks(
      courseData.relatedResourcesCollection.items
    );
  }
  if (
    courseData.relatedContentCollection &&
    courseData.relatedContentCollection.items
  ) {
    data.relatedContentCards = relatedContentToCardData(
      courseData.relatedContentCollection.items
    );
  }
  return data;
};

export const routineResponseToData = (res: AxiosResponse<any>) => {
  const routineData = res.data.data.routine;
  const contentType = ContentType.ROUTINE;
  try {
    const data: RoutinePageData = {
      title: routineData.itemTitle,
      id: routineData.sys.id,
      heroImage: routineData.itemHeroImage && routineData.itemHeroImage.url,
      objectivesList: routineData.objectives,
      courseDescription: routineData.itemShortDescription,
      itemDescription: routineData.itemDescription,
      exploreContentfulObj: routineData.routinePartOne,
      reflectContentfulObj: routineData.routinePartTwo,
      tagList: (routineData && routineData.tags) || undefined,
      labelData: gqlToLabelData({
        type: contentType,
        minCompletionTime: routineData.minCompletionTime || undefined,
        maxCompletionTime: routineData.maxCompletionTime || undefined,
        numberOfKids: routineData.numberOfKids || undefined,
        grades: routineData.grades || undefined,
        credit: routineData.credit || undefined
      }),
      navLinks: [
        { label: 'Some Link', value: 'https://google.com' },
        { label: routineData.itemTitle, value: '' }
      ],
      resourceLinks: [],
      relatedContentCards: [],
      url: routineData.courseUrl,
      why: routineData.why,
      audienceCollection: getAudienceArray(routineData.audienceCollection)
    };
    if (
      routineData.relatedResourcesCollection &&
      routineData.relatedResourcesCollection.items
    ) {
      data.resourceLinks = buildRelatedLinks(
        routineData.relatedResourcesCollection.items
      );
    }
    if (
      routineData.relatedContentCollection &&
      routineData.relatedContentCollection.items
    ) {
      data.relatedContentCards = relatedContentToCardData(
        routineData.relatedContentCollection.items
      );
    }
    return data;
  }
  catch (error) {
    console.error('Error Parsing Response', error);
    return {
      title: '',
      id: '',
      heroImage: '',
      objectivesList: [],
      courseDescription: '',
      tagList: [],
      labelData: [],
      navLinks: [],
      resourceLinks: [],
      relatedContentCards: [],
      url: '',
      itemDescription: '',
      exploreContentfulObj: undefined,
      reflectContentfulObj: undefined,
      why: ''
    };
  }
};

export const strategyResponseToData = (res: AxiosResponse<any>) => {
  const strategyData = res.data.data.strategy;
  const contentType = ContentType.STRATEGY;
  try {
    const data: StrategyPageData = {
      title: strategyData.itemTitle,
      id: strategyData.sys.id,
      heroImage: strategyData.itemHeroImage && strategyData.itemHeroImage.url,
      objectivesList: strategyData.objectives,
      courseDescription: strategyData.itemShortDescription,
      itemDescription: strategyData.itemDescription,
      exploreContentfulObj: strategyData.strategyPartOne,
      reflectContentfulObj: strategyData.strategyPartTwo,
      tagList: (strategyData && strategyData.tags) || undefined,
      labelData: gqlToLabelData({
        type: contentType,
        minCompletionTime: strategyData.minCompletionTime || undefined,
        maxCompletionTime: strategyData.maxCompletionTime || undefined,
        numberOfKids: strategyData.numberOfKids || undefined,
        grades: strategyData.grades || undefined,
        credit: strategyData.credit || undefined
      }),
      navLinks: [
        { label: 'Some Link', value: 'https://google.com' },
        { label: strategyData.itemTitle, value: '' }
      ],
      resourceLinks: [],
      relatedContentCards: [],
      url: '',
      why: strategyData.why,
      audienceCollection: getAudienceArray(strategyData.audienceCollection)
    };
    if (
      strategyData.relatedResourcesCollection &&
      strategyData.relatedResourcesCollection.items
    ) {
      data.resourceLinks = buildRelatedLinks(
        strategyData.relatedResourcesCollection.items
      );
    }
    if (
      strategyData.relatedContentCollection &&
      strategyData.relatedContentCollection.items
    ) {
      data.relatedContentCards = relatedContentToCardData(
        strategyData.relatedContentCollection.items
      );
    }
    return data;
  }
  catch (error) {
    console.error('Error Parsing Response', error);
    return {
      title: '',
      id: '',
      heroImage: '',
      objectivesList: [],
      courseDescription: '',
      tagList: [],
      labelData: [],
      navLinks: [],
      resourceLinks: [],
      relatedContentCards: [],
      url: '',
      itemDescription: '',
      exploreContentfulObj: undefined,
      reflectContentfulObj: undefined,
      why: ''
    };
  }
};

export const mediaResourceResponseToData = (contentType: string, res: AxiosResponse<any>) => {
  const resourceData = res.data.data[contentType];
  try {
    const data: MediaResourcePageData = {
      title: resourceData.itemTitle,
      id: resourceData.sys.id,
      heroImage: resourceData.itemHeroImage && resourceData.itemHeroImage.url,
      objectivesList: resourceData.objectives || [],
      itemDescription: resourceData.itemDescription || undefined,
      tagList: (resourceData && resourceData.tags) || undefined,
      labelData: gqlToLabelData({
        type: ContentType.RESOURCE,
        minCompletionTime: resourceData.minCompletionTime || undefined,
        maxCompletionTime: resourceData.maxCompletionTime || undefined,
        numberOfKids: resourceData.numberOfKids || undefined,
        grades: resourceData.grades || undefined,
        credit: resourceData.credit || undefined
      }),
      navLinks: [
        { label: 'Some Link', value: 'https://google.com' },
        { label: resourceData.itemTitle, value: '' }
      ],
      relatedContentCards: [],
      url: resourceData.url || undefined,
      allowDownload: resourceData.allowFileDownload || undefined,
      fileUrl: resourceData.file && resourceData.file
        ? resourceData.file.url
        : undefined,
      audienceCollection: getAudienceArray(resourceData.audienceCollection),
      printables: resourceData.fileCollection && getPrintables(resourceData.fileCollection),
      resourcesBodyContentfulObj: resourceData.resourcesBodyContent
    };
    // Add words to Type Label
    const resourceTypes: Array<string> =
      (resourceData &&
        resourceData.resourceType &&
        resourceData.resourceType.map(
          (text: string) => `${text.charAt(0).toUpperCase()}${text.slice(1)}`
        )) ||
      [];
    const labelIndex = data.labelData.findIndex(
      ld => ld.type === LabelType.TYPE
    );
    const old = data.labelData[labelIndex];
    if (resourceTypes.length > 0) {
      old.value = `${old.value}  •  ${resourceTypes.join('  •  ')}`;
      data.labelData[labelIndex] = old;
    }
    if (
      resourceData.relatedContentCollection &&
      resourceData.relatedContentCollection.items
    ) {
      data.relatedContentCards = relatedContentToCardData(
        resourceData.relatedContentCollection.items
      );
    }
    return data;
  }
  catch (error) {
    console.error('Error Parsing Response', error);
    return {
      title: '',
      id: '',
      heroImage: '',
      objectivesList: [],
      courseDescription: '',
      tagList: [],
      labelData: [],
      navLinks: [],
      relatedContentCards: [],
      url: '',
      itemDescription: '',
      exploreContentfulObj: undefined,
      reflectContentfulObj: undefined,
      allowDownload: resourceData.allowFileDownload,
      fileUrl: ''
    };
  }
};

export const learnAboutResponseToData = (res: any) => {
  const {
    data: { data: { learnAbout: data } },
    related: { data: { data: { learnAbout: relatedData } } }
  } = res;
  const formattedData = {
    id: data.sys.id,
    title: data.itemTitle,
    heroImage: data.itemHeroImage.url || '',
    itemDescription: data.itemDescription,
    itemShortDescription: data.itemShortDescription,
    learnAboutContent: data.learnAboutContent,
    relatedContent: [],
    audienceCollection: data.audienceCollection.items.map(({itemTitle}: any) => itemTitle),
    tags: data.tags
  } as LearnAboutPageData;

  if (relatedData.relatedContentCollection && relatedData.relatedContentCollection.items) {
    formattedData.relatedContent = relatedContentToCardData(relatedData.relatedContentCollection.items);
  }

  return formattedData;
};

export const contentPreviewResponseToData = (res: any) => {
  const {
    data: { data: { contentPreview: data } },
    related: { data: { data: { contentPreview: relatedData } } }
  } = res;
  const contentType = ContentType.CONTENT_PREVIEW;
  const releaseTimeLabel = data.releaseDate;
  const formattedData = {
    id: data.sys.id,
    title: data.itemTitle,
    heroImage: data.itemHeroImage.url || '',
    itemDescription: data.itemDescription,
    itemShortDescription: data.itemShortDescription,
    labelData: gqlToLabelData({
      type: contentType,
      date: releaseTimeLabel,
    }),
    releaseDate: data.releaseDate,
    marketingContent: data.marketingContent,
    relatedContent: [],
    audienceCollection: data.audienceCollection.items.map(({itemTitle}: any) => itemTitle),
    tags: data.tags
  } as ContentPreviewPageData;

  if ( relatedData && relatedData.relatedContentCollection && relatedData.relatedContentCollection.items) {
    formattedData.relatedContent = relatedContentToCardData(relatedData.relatedContentCollection.items);
  }

  return formattedData;
};

export const pdfResourceResponseToData = (res: AxiosResponse<any>) => {
  const routineData = res.data.data.mediaResource;
  const contentType = ContentType.RESOURCE;
  try {
    const data: PDFViewPageData = {
      title: routineData.itemTitle,
      id: routineData.sys.id,
      allowDownload: routineData.allowFileDownload,
      fileUrl: routineData.file.url,
      contentType: routineData.file.contentType
    };

    return data;
  }
  catch (error) {
    console.error('Error Parsing Response', error);
    return {
      title: '',
      id: '',
      allowDownload: routineData.allowFileDownload,
      fileUrl: '',
      contentType: ''
    };
  }
};

export const activityResponseToData = (
  res: AxiosResponse<any>,
  activityType: ActivityType
) => {
  const activityData = res.data.data[activityType];
  const contentType = ContentType.ACTIVITY;
  try {
    const data: ActivityPageData = {
      title: activityData.itemTitle,
      id: activityData.sys.id,
      activityType,
      heroImage: activityData.itemHeroImage && activityData.itemHeroImage.url,
      objectivesList: activityData.objectives,
      itemDescription: activityData.itemDescription,
      itemTagline: activityData.itemTagline || undefined,
      tagList: (activityData && activityData.tags) || undefined,
      labelData: gqlToLabelData({
        type: contentType,
        additionalType: `${activityData.activityType && activityData.activityType.length
          ? ` • ${activityData.activityType}`
          : ` • ${activityTypeToSubtype(activityType)}`
          }${activityData.activityTheme && activityData.activityTheme.length
            ? " • " + activityData.activityTheme.join(" • ")
            : ""
          }${activityType === ActivityType.BLAST ? " • Blast" : ""}`,
        time: activityData.completionTime || undefined,
        numberOfKids: activityData.numberOfKids || undefined,
        grades: activityData.grades || undefined,
        credit: activityData.credit || undefined
      }),
      navLinks: [
        { label: 'Some Link', value: 'https://google.com' },
        { label: activityData.itemTitle, value: '' }
      ],
      url: activityData.url || undefined,
      feedbackUrl: activityData.surveyLink,
      lifeSkillsList: activityData.lifeSkills &&
        activityData.lifeSkills.length > 0 ?
        activityData.lifeSkills.sort() : [],


      leadershipSkills: activityData.leadershipSkillsText,
      beforeYouStartTitle: activityData.beforeYouStartTitle,
      beforeYouStartTextObj: activityData.beforeYouStartText,
      academicConnectionsList: activityData.academicConnections &&
        activityData.academicConnections.length > 0 ?
        activityData.academicConnections.sort() : [],
      academicConnectionsNotes: activityData.academicConnectionsNotes,
      setupContentfulObj: activityData.setupTeacher,
      materialsTeacher: activityData.materialsTeacher,
      materialsStudent: activityData.materialsKids,
      printables:
        activityData.printablesCollection &&
          activityData.printablesCollection.items.length
          ? activityData.printablesCollection.items.map((item: any) => ({
            label: (item && item.file && item.file.fileName) || '',
            value: (item && item.file && item.file.url) || ''
          }))
          : undefined,
      activityOneContentfulObj: activityData.activityPartOne,
      technicallyOneContentfulObj: activityData.technicallySpeaking1,
      activityTwoContentfulObj: activityData.activityPartTwo,
      technicallyTwoContentfulObj: activityData.technicallySpeaking2,
      lifeSkillsOneContentfulObj: activityData.lifeSkillsCallout1,
      activityThreeContentfulObj: activityData.activityPartThree,
      technicallyThreeContentfulObj: activityData.technicallySpeaking3,
      lifeSkillsTwoContentfulObj: activityData.lifeSkillsReminder2,
      gradeRangeContentfulObj: activityData.activityPartFour,
      gradeRangeWhatElseContentfulObj: activityData.activityPartFive,
      springboardOneContentfulObj: activityData.springboardPartOne,
      gameDirections: activityData.gameDirections,
      gameStepsContentfulObj: activityData.gameSteps,
      relatedContentCards: [],
      resourceLinks: [],
      setupTime: `(${minsToString(activityData.setupTime)})`,
      activityBanner:
        activityData.activityBanner && activityData.activityBanner.url,
      audienceCollection: getAudienceArray(activityData.audienceCollection)
    };

    if (activityType === ActivityType.FITNESS) {
      data.shapeStandardsList = activityData.shapeStandards && activityData.shapeStandards.length > 0
        ? activityData.shapeStandards.sort() : [];
      data.fitnessFocusList = activityData.fitnessFocus && activityData.fitnessFocus.length > 0
        ? activityData.fitnessFocus.sort() : [];
      data.functionalMovementsList = activityData.functionalMovements && activityData.functionalMovements.length > 0
        ? activityData.functionalMovements.sort() : [];
      data.activatesList = activityData.activates && activityData.activates.length > 0
        ? activityData.activates.sort() : [];
      data.materialsEquipmentTeacherList = activityData.materialsEquipmentTeacher && activityData.materialsEquipmentTeacher.length > 0
        ? activityData.materialsEquipmentTeacher.sort() : [];
      data.materialsEquipmentKidsList = activityData.materialsEquipmentKids && activityData.materialsEquipmentKids.length > 0
        ? activityData.materialsEquipmentKids.sort() : [];
      data.setupTeacherTxt = activityData.setupTeacher;
      data.modificationsTxt = activityData.modifications;
      data.blastDescription = activityData.blastDescription;
      data.activityPartOneTime = activityData.activityPartOneTime;
      data.activityTimePartTwoTime = activityData.activityTimePartTwoTime;
      data.activityPartThreeTime = activityData.activityPartThreeTime;
      data.activityPartFourTime = activityData.activityPartFourTime;
    }
    if (
      activityData.relatedContentCollection &&
      activityData.relatedContentCollection.items.length > 0
    ) {
      data.relatedContentCards = relatedContentToCardData(
        activityData.relatedContentCollection.items
      );
    }
    if (
      activityData.relatedResourcesCollection &&
      activityData.relatedResourcesCollection.items.length > 0
    ) {
      data.resourceLinks = buildRelatedLinks(
        activityData.relatedResourcesCollection.items
      );
    }

    if (activityData.videosCollection && activityData.videosCollection.items.length) {
      data.demonstrationVideos = activityData.videosCollection.items.map((video: any) => ({
        title: video.itemTitle,
        description: video.itemShortDescription,
        imageUrl: video.itemThumbnailLogo
          ? video.itemThumbnailLogo.url
          : '',
        videoUrl: video.file
          ? video.file.url
          : ''
      }));
    }

    if (
      activityData.relatedBlastsCollection &&
      activityData.relatedBlastsCollection.items.length > 0
    ) {
      data.relatedBlastsVideos = relatedContentToCardData(
        activityData.relatedBlastsCollection.items
      );
    }

    return data;
  }
  catch (error) {
    console.error('Error Parsing Response', error);
    return {
      title: '',
      id: '',
      heroImage: '',
      objectivesList: [],
      courseDescription: '',
      tagList: [],
      labelData: [],
      navLinks: [],
      relatedContentCards: [],
      url: '',
      itemDescription: '',
      exploreContentfulObj: undefined,
      reflectContentfulObj: undefined,
      resourceLinks: [],
      activityType,
      gameDirections: '',
      gameStepsContentfulObj: {}
    };
  }
};
