import { PluginObject } from 'vue';
import VueRouter from 'vue-router';
import { AnalyticsService, provideAnalytics, routeAnalyticsMiddleware } from '.';

/**
 * Vue plugin for providing analytics service and setting up route-based analytics tracking middleware.
 */
export const analyticsPlugin: PluginObject<AnalyticsPluginOptions> = {
  install(Vue, options): void {
    if (!options || !options.service || !options.router) {
      return console.error('Analytics plugin requires the service and router options.');
    }

    provideAnalytics(Vue, options.service);

    routeAnalyticsMiddleware(options.router, options.service);
  }
};

/**
 * The Segment analytics Vue plugin options.
 */
export interface AnalyticsPluginOptions {
  /**
   * The Segment analytics service to provide.
   */
  service?: AnalyticsService;
  /**
   * The Vue Router instance for setting up route-based analytics tracking middleware.
   */
  router?: VueRouter;
}
