import Vue from "vue";
import { createAuth0Client } from "@auth0/auth0-spa-js";
import { GenericError } from "@auth0/auth0-spa-js";
import APP_UTILITIES from "@/utilities/commonFunctions";
import jwt_decode from "jwt-decode";

/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);
let instance;
/** Returns the current instance of the SDK */
export const getInstance = () => instance;
/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin,
  ...options
}) => {
  if (instance) {
    return instance;
  }
  // The 'instance' is simply a Vue object
  instance = new Vue({
    data() {
      return {
        loading: true,
        isAuthenticated: false,
        user: {},
        auth0Client: null,
        popupOpen: false,
        error: null,
      };
    },
    methods: {
      /** Authenticates the user using a popup window */
      async loginWithPopup(options, config) {
        this.popupOpen = true;
        try {
          await this.auth0Client.loginWithPopup(options, config);
          this.user = await this.auth0Client.getUser();
          this.isAuthenticated = await this.auth0Client.isAuthenticated();
          this.error = null;
        } catch (e) {
          this.error = e;
          // eslint-disable-next-line
          console.error(e);
        } finally {
          this.popupOpen = false;
        }
        this.user = await this.auth0Client.getUser();
        this.isAuthenticated = true;
      },
      /** Handles the callback when logging in using a redirect */
      async handleRedirectCallback() {
        this.loading = true;
        try {
          await this.auth0Client.handleRedirectCallback();
          this.user = await this.auth0Client.getUser();
          this.isAuthenticated = true;
          this.error = null;
        } catch (e) {
          this.error = e;
        } finally {
          this.loading = false;
        }
      },
      /** Authenticates the user using the redirect method */
      loginWithRedirect(o) {
        return this.auth0Client.loginWithRedirect(o);
      },
      /** Returns all the claims present in the ID token */
      getIdTokenClaims(o) {
        return this.auth0Client.getIdTokenClaims(o);
      },
      /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
      async getTokenSilently(o) {
        try {
          const token = await this.auth0Client.getTokenSilently(o);
          const { isBxPortal } = jwt_decode(token);

          // Only admin users have access, as we're using the same login page for
          // Guardians/Admins in Arly. We need to check the user's permissions
          if (!isBxPortal) {
            await this.auth0Client.logout({
              logoutParams: {
                returnTo: window.location.origin,
              },
            });

            APP_UTILITIES.removeCookies();
            localStorage.clear();
          }

          return token;
        } catch (e) {
          if (e instanceof GenericError && e.error === "login_required") {
            APP_UTILITIES.removeCookies();
            window.location.href =
              process.env.VUE_APP_BASE_IDENTITY_REDIRECT_URL;
          }

          throw e;
        }
      },
      /** Gets the access token using a popup window */
      getTokenWithPopup(o) {
        return this.auth0Client.getTokenWithPopup(o);
      },
      /** Logs the user out and removes their session on the authorization server */
      logout(o) {
        return this.auth0Client.logout(o);
      },
      getAuthClient() {
        return this.auth0Client;
      },
      isUserAuthenticated() {
        return this.auth0Client.isAuthenticated();
      },
      async iniAuth() {
        // Create a new instance of the SDK client using members of the given options object
        this.auth0Client = await createAuth0Client({
          ...options,
          authorizationParams: {
            redirect_uri: redirectUri,
            audience: process.env.VUE_APP_AUTH0_AUDIENCE,
            ...options.authorizationParams,
          },
        });
      },
    },
    /** Use this lifecycle method to instantiate the SDK client */
    created() {
      this.iniAuth();
    },
  });
  return instance;
};
// Create a simple Vue plugin to expose the wrapper object throughout the application
export const Auth0Plugin = {
  install(Vue, options) {
    Vue.prototype.$auth = useAuth0(options);
  },
};
