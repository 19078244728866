import {
  httpClient,
  httpIdentityClient,
  httpCMSClient,
  httpLMSClient,
  httpClientEnrollment,
  httpCMSAnalyticsClient,
} from "../common/httpClient";
import API_CONST from "@/constants/ApiConst";
import account from "../../assets/JSON/account_list.json";
import APP_UTILITIES from "@/utilities/commonFunctions";
import {
  AccountListPayload,
  ProgramConfigureObj,
  CreateProgramObj,
  AccountDtById,
  DownloadPayload,
  IDownloadUsersPayload,
  IGroupsBySiteid,
  IAdaWeekLinePayload,
} from "@/Model/model";
import APP_CONST from "@/constants/AppConst";
import { readDataByKey } from "@/utilities/idbUtility";
import { getInstance } from "@/auth";
import { GuardianRevokeAccessDto } from "@/Model/shared/types";

export async function userAccounts(range: any): Promise<any> {
  const response = await new Promise((resolve, reject) => {
    const accounts = account.accounts;
    const filtered = accounts.slice(range.start, range.end);
    setTimeout(function () {
      resolve({ items: filtered, total_items: account.count });
    }, 250);
  });
  return response;
}

export async function searchTable(search_input: string): Promise<any> {
  const response = await new Promise((resolve, reject) => {
    let accounts = account.accounts.filter((row: any) => {
      for (let key in row) {
        if (String(row[key]).indexOf(search_input) !== -1) {
          return true;
        }
      }
      return false;
    });
    let items = 0;
    if (search_input) {
      accounts = accounts.slice(0, 14);
      items = accounts.length;
    } else {
      accounts = account.accounts.slice(0, 14);
      items = account.accounts.length;
    }
    setTimeout(function () {
      resolve({ items: accounts, total_items: items });
    }, 250);
  });
  return response;
}

function getAccountList(payload: AccountListPayload) {
  let queryString = "";
  let args: any = payload;
  for (var k in args) {
    if (k == "search") {
      args[k] = encodeURIComponent(args[k]);
    }
    if (args !== "isExactMatch" && args[k]) {
      queryString = queryString + k + "=" + args[k] + "&";
    } else {
      queryString = queryString + k + "=" + args[k] + "&";
    }
  }
  queryString = queryString.slice(0, -1);

  return httpClient()
    .get(API_CONST.ACCOUNTS + "?" + queryString)
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error) => {
      return {
        data: error.data,
        status: error.status,
        statusText: error.statusText,
      };
    });
}

function getManageList(payload: any) {
  let queryString = "";
  for (var k in payload) {
    if (k == "search") {
      payload[k] = encodeURIComponent(payload[k]);
    }
    if (payload[k]) {
      queryString = queryString + k + "=" + payload[k] + "&";
    }
  }
  queryString = queryString.slice(0, -1);
  return httpIdentityClient()
    .get(`${API_CONST.GET_MANAGE_USERS}?${queryString}`)
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error) => {
      return {
        data: error.data,
        status: error.status,
        statusText: error.statusText,
      };
    });
}

export function getAccountListResponseObj(
  payload: AccountListPayload
): Promise<any> {
  if (APP_UTILITIES.checkBrowserStatus()) {
    return getAccountList(payload);
  } else {
    return readDataByKey(
      APP_CONST.IDB_ACCOUNT_LIST,
      APP_UTILITIES.getCookie("user_id")
    )
      .then((response: any) => {
        if (response) {
          return {
            data: response,
            status: APP_CONST.RESPONSE_200,
            statusText: APP_CONST.SUCCESS_RESPONSE_STATUS,
          };
        }
      })
      .catch((error) => {
        return {
          data: error.data,
          status: APP_CONST.RESPONSE_400,
          statusText: error.statusText,
        };
      });
  }
}

export function getManageListResponseObj(payload: any): Promise<any> {
  if (APP_UTILITIES.checkBrowserStatus()) {
    return getManageList(payload);
  } else {
    return readDataByKey(
      APP_CONST.IDB_ACCOUNT_LIST,
      APP_UTILITIES.getCookie("user_id")
    )
      .then((response: any) => {
        if (response) {
          return {
            data: response,
            status: APP_CONST.RESPONSE_200,
            statusText: APP_CONST.SUCCESS_RESPONSE_STATUS,
          };
        }
      })
      .catch((error) => {
        return {
          data: error.data,
          status: APP_CONST.RESPONSE_400,
          statusText: error.statusText,
        };
      });
  }
}

export function getProgramListResponseObj(
  payload: AccountListPayload
): Promise<any> {
  let queryString = "";
  let args: any = payload;
  for (var k in args) {
    if (args !== "isExactMatch" && args[k]) {
      if (k == "search") {
        args[k] = encodeURIComponent(args[k]);
      }
      queryString = queryString + k + "=" + args[k] + "&";
    } else {
      queryString = queryString + k + "=" + args[k] + "&";
    }
  }
  queryString = queryString.slice(0, -1);
  return httpClient()
    .get(API_CONST.PROGRAMS + "?" + queryString)
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error) => {
      return {
        data: error.data,
        status: error.status,
        statusText: error.statusText,
      };
    });
}

export function getCreateProgramRes(payload: CreateProgramObj) {
  return httpClient()
    .post(API_CONST.CONFIGURE_PROGRAM, payload)
    .then((response) => {
      return {
        data: response,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error) => {
      const res = error;
      return {
        data: res.data || res.data.value || res.data,
        status: res.status,
        statusText: res.statusText,
      };
    });
}
export function sendProgramInvite(payload: CreateProgramObj): Promise<any> {
  return httpClient()
    .post(API_CONST.CONFIGURE_PROGRAM + "/inviteprogramadmin", payload)
    .then((response) => {
      return {
        data: response,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error) => {
      const res = error;
      return {
        data: res.data || res.data.value || res.data,
        status: res.status,
        statusText: res.statusText,
      };
    });
}
export function getSaveConfigureResponse(
  payload: ProgramConfigureObj
): Promise<any> {
  return httpClient()
    .post(API_CONST.CONFIGURE_PROGRAM, payload)
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error) => {
      const res = error;
      return {
        data: res.data.Message || res.data.value || res.data,
        status: res.status,
        statusText: res.statusText,
      };
    });
}

export function getaccountbyid(payload: AccountDtById): Promise<any> {
  return httpClient()
    .get(API_CONST.ACCOUNT_BY_ID + "/" + payload.accountId)
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error) => {
      return {
        data: error.data,
        status: error.status,
        statusText: error.statusText,
      };
    });
}

export function getUserDetailsResponseObj(id: number): Promise<any> {
  return httpIdentityClient()
    .get(`${API_CONST.USERID}${id}`)
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error) => {
      return {
        data: error.data,
        status: error.status,
        statusText: error.statusText,
      };
    });
}

export function updateProgramStatus(payload: any): Promise<any> {
  return httpClient()
    .put(API_CONST.UPDATE_PROGRAM + "/" + payload.programId, payload.status)
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error) => {
      return {
        data: error.data,
        status: error.status,
        statusText: error.statusText,
      };
    });
}

export function resetProgram(payload: any): Promise<any> {
  return httpCMSClient()
    .post(API_CONST.RESET_PROGRAM, payload)
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error) => {
      return {
        data: error.data,
        status: error.status,
        statusText: error.statusText,
      };
    });
}

export function updateProgramWithEditedDataAPI(payload: any): Promise<any> {
  return httpClient()
    .put(API_CONST.EDIT_PROGRAM_UPDATE + "/" + payload.programDto.id, payload)
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error) => {
      return {
        data: error.data,
        status: error.status,
        statusText: error.statusText,
      };
    });
}

export function AccountInfo(id: number): Promise<any> {
  return httpClient()
    .get(`${API_CONST.FIRST_LOGIN_ACCOUNT_INFO}/${id}`)
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error) => {
      return {
        data: error.data,
        status: error.status,
        statusText: error.statusText,
      };
    });
}

export function getFetchConfigResponse(programId: number) {
  if (programId === 0) {
    return;
  }
  return httpClient()
    .get(`${API_CONST.GET_PROGRAM_DATA}/${programId}`)
    .then((response: any) => {
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error: any) => {
      return {
        data: error.data,
        status: error.status,
        statusText: error.statusText,
      };
    });
}

export function getFetchConfigResponseProgram(programId: number) {
  if (programId === 0) {
    return;
  }
  return httpClient()
    .get(`${API_CONST.GET_PROGRAM_DATA}/${programId}/info`)
    .then((response: any) => {
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error: any) => {
      return {
        data: error.data,
        status: error.status,
        statusText: error.statusText,
      };
    });
}

export function getFetchProgramDetails(programId: number) {
  if (programId === 0) {
    return;
  }
  return httpClient()
    .get(`${API_CONST.GET_PROGRAM_DATA}/${programId}`)
    .then((response: any) => {
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error: any) => {
      return {
        data: error.data,
        status: error.status,
        statusText: error.statusText,
      };
    });
}
export function getFirstTimeQuestions(
  productId: number,
  programStartDate: string,
  programEndDate: string
) {
  return httpCMSClient()
    .get(
      `${API_CONST.QUESTIONAIRE}?productIds=${productId}&programStartDate=${programStartDate}&programEndDate=${programEndDate}`
    )
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error) => {
      return {
        data: error.data,
        status: error.status,
        statusText: error.statusText,
      };
    });
}

export function getFirstTimeTasks(productIdArray: any) {
  return httpCMSClient()
    .get(`task-products?productIds=${productIdArray[0]}`)
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error) => {
      return {
        data: error.data,
        status: error.status,
        statusText: error.statusText,
      };
    });
}

export function getSavedProgramData(programId: number) {
  if (programId === 0) {
    return;
  }
  return httpClient()
    .get(`${API_CONST.GET_PROGRAM_DATA}/${programId}/info`)
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error) => {
      return {
        data: error.data,
        status: error.status,
        statusText: error.statusText,
      };
    });
}

export function getAutoSaveProgram(obj: any) {
  return httpClient()
    .get(
      `${API_CONST.AUTO_SAVE_PROGRAM}?programId=${obj.programId}&accountId=${
        obj.accountId
      }&entityTypes=${obj.entityTypes ? obj.entityTypes : 2}`
    )
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error) => {
      return {
        data: error.data,
        status: error.status,
        statusText: error.statusText,
      };
    });
}

export function FetchConfigProgByIdResponse(programId: number) {
  return httpClient()
    .get(`${API_CONST.FETCH_CONFIG_PROGRAM_BY_ID}/${programId}`)
    .then((response: any) => {
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error: any) => {
      return {
        data: error.data,
        status: error.status,
        statusText: error.statusText,
      };
    });
}

export function getLMSLinks() {
  return httpLMSClient()
    .get(`${API_CONST.LMS_LINKS}`)
    .then((response: any) => {
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error: any) => {
      return {
        data: error.data,
        status: error.status,
        statusText: error.statusText,
      };
    });
}

export function getLMSCourseStatus() {
  return httpLMSClient()
    .get(`${API_CONST.LMS_COURSE_STATUS}`)
    .then((response: any) => {
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error: any) => {
      return {
        data: error.data,
        status: error.status,
        statusText: error.statusText,
      };
    });
}

export function fetchTasksByProd(taskFetchObj: any) {
  return httpCMSClient()
    .get(
      `${API_CONST.GET_TASKS_PROD}?productIds=${taskFetchObj.productId}&programStartDate=${taskFetchObj.programStartDate}&programEndDate=${taskFetchObj.programEndDate}`
    )
    .then((response: any) => {
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error: any) => {
      return {
        data: error.data,
        status: error.status,
        statusText: error.statusText,
      };
    });
}

export function logoutUser() {
  const authService = getInstance();

  return authService.logout({
    logoutParams: {
      returnTo: window.location.origin,
    },
  });
}

export function getUserById(parsedId: any) {
  return httpIdentityClient()
    .get(`users/${parsedId}`)
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error) => {
      const res = error;
      return { data: res, status: res.status, statusText: res.statusText };
    });
}

export function getRoasterErrorTable(payload: any) {
  return httpClientEnrollment()
    .get(
      API_CONST.GET_ROASTER_ERROR +
        `?siteId=${payload.siteId}&programId=${payload.programId}&step=${payload.stepNumber}&search=${payload.search}&active=${payload.active}&page=${payload.page}&count=${payload.count}&sortField=${payload.sortField}&sortDir=${payload.sortDir}`
    )
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error) => {
      return {
        data: error.data,
        status: error.status,
        statusText: error.statusText,
      };
    });
}

export function deleteRoasterErrorTable(siteId: number) {
  return httpClientEnrollment()
    .delete(API_CONST.GET_ROASTER_ERROR + "/" + `site/${siteId}`)
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error) => {
      return {
        data: error.data,
        status: error.status,
        statusText: error.statusText,
      };
    });
}

export function getRoasterTable(payload: any) {
  let step = payload.stepNumber == 4 ? payload.stepNumber : -1;
  return httpClient()
    .get(
      API_CONST.GET_ROASTER_STEP5 +
        `?siteId=${payload.siteId}&programId=${payload.programId}&step=${step}&search=${payload.search}&active=${payload.active}&page=${payload.page}&count=${payload.count}&sortField=${payload.sortField}&sortDir=${payload.sortDir}`
    )
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error) => {
      return {
        data: error.data,
        status: error.status,
        statusText: error.statusText,
      };
    });
}

export function getRoasterData(payload: any) {
  let step = payload.stepNumber == 4 ? payload.stepNumber : -1;
  return httpClientEnrollment()
    .get(
      API_CONST.GET_ROASTER_STEP5 +
        `?siteId=${payload.siteId}&programId=${payload.programId}&step=${step}&accountId=${payload.accountId}`
    )
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error) => {
      return {
        data: error.data,
        status: error.status,
        statusText: error.statusText,
      };
    });
}

export function getScholarDetails(payload: any) {
  return httpClientEnrollment()
    .get(
      API_CONST.GET_SCHOLAR_DETAILS +
        `?ssmId=${payload.ssmId}&startDate=${payload.startDate}&endDate=${payload.endDate}`
    )
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error) => {
      return {
        data: error.data,
        status: error.status,
        statusText: error.statusText,
      };
    });
}

export function editConfigureResponse(
  payload: ProgramConfigureObj
): Promise<any> {
  return httpClient()
    .put(API_CONST.EDIT_PROGRAM + "/" + payload.programDto.programId, payload)
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error) => {
      return {
        data: error && error.data ? error.data : error,
        status: error && error.status ? error.status : error,
        statusText: error.statusText,
      };
    });
}

export function deleteSite(siteId: number): Promise<any> {
  return httpClient()
    .delete(API_CONST.POST_SITE_DATA + "/" + siteId)
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error) => {
      return {
        data: error && error.data ? error.data : error,
        status: error && error.status ? error.status : error,
        statusText: error.statusText,
      };
    });
}

export function getOwnersByProgramId(programId: number) {
  return httpIdentityClient()
    .get(`${API_CONST.USERID}${API_CONST.PROGRAM}${programId}`)
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error) => {
      return {
        data: error.data,
        status: error.status,
        statusText: error.statusText,
      };
    });
}

export function getOwnersByAccountId(accountId: number, programId: number) {
  return httpIdentityClient()
    .get(
      `${API_CONST.USERID}${API_CONST.ACCOUNT}${accountId}/${API_CONST.PROGRAM}${programId}`
    )
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error) => {
      return {
        data: error.data,
        status: error.status,
        statusText: error.statusText,
      };
    });
}

export function getUserInfomartionByUserId(id: number): Promise<any> {
  return httpIdentityClient()
    .get(`${API_CONST.USERID}${API_CONST.INFO}${id}`)
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error) => {
      return {
        data: error.data,
        status: error.status,
        statusText: error.statusText,
      };
    });
}

export function getSiteListByAccountId(id: number): Promise<any> {
  return httpClient()
    .get(`${API_CONST.SITESONACCOUNTID}${id}`)
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error) => {
      return {
        data: error.data,
        status: error.status,
        statusText: error.statusText,
      };
    });
}

export function getProgramsList(payload: {
  id: number;
  userId: number;
  filterKey: number;
}): Promise<any> {
  const highest_role: any = APP_UTILITIES.getCookie(APP_CONST.HIGHEST_ROLE);
  let status: string = "all";
  if (highest_role == 6 || highest_role == 7) {
    status = "active";
  }
  return httpIdentityClient()
    .get(
      `users/${payload.userId}/account/${payload.id}/starprogramandsessiondata/${payload.filterKey}`
    )
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error) => {
      return {
        data: error.data,
        status: error.status,
        statusText: error.statusText,
      };
    });
}

export function selectStar(payload: any) {
  return httpIdentityClient()
    .post(`users/progsitestar`, payload)
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error) => {
      const res = error;
      return {
        data: res.data.Message || res.data.value || res.data,
        status: res.status,
        statusText: res.statusText,
      };
    });
}

export function getAllPrograms(): Promise<any> {
  return httpClient()
    .get(API_CONST.GET_PROGRAMS_LIST)
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error) => {
      return {
        data: error.data,
        status: error.status,
        statusText: error.statusText,
      };
    });
}

export function getAllUsers(payload: any): Promise<any> {
  let encodedSearch = encodeURIComponent(payload.Search);
  payload.Search = encodedSearch;
  return httpIdentityClient()
    .get(
      `${API_CONST.USERS_LIST}?SearchField=${payload.SearchField}&GlobalSearch=${payload.GlobalSearch}&Id=${payload.Id}&Active=${payload.Active}&Search=${payload.Search}&RoleType=${payload.RoleType}&Page=${payload.Page}&Count=${payload.Count}&SortField=${payload.SortField}&SortDir=${payload.SortDir}&isExactMatch=${payload.isExactMatch}`
    )
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error) => {
      return {
        data: error.data,
        status: error.status,
        statusText: error.statusText,
      };
    });
}

export function deleteUsers(payload: any) {
  return httpIdentityClient()
    .delete(`${API_CONST.BULK}`, { data: payload })
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error) => {
      const res = error;
      return {
        data: res.data.Message || res.data.value || res.data,
        status: res.status,
        statusText: res.statusText,
      };
    });
}

/**
 * Save bulk sites
 *
 * @param payload
 * @returns
 */

export function saveSiteSessionDetails(payload: any): Promise<any> {
  return httpClient()
    .post(API_CONST.BULK_SITES, payload)
    .then((response) => {
      return {
        data: response,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error) => {
      const res = error;
      return {
        data: res.data || res.data.value || res.data,
        status: res.status,
        statusText: res.statusText,
      };
    });
}

export function updateSiteSessionDetails(payload: any): Promise<any> {
  return httpClient()
    .put(`${API_CONST.POST_SITE_DATA}/${payload.siteId}`, payload)
    .then((response) => {
      return {
        data: response,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error) => {
      const res = error;
      return {
        data: res.data || res.data.value || res.data,
        status: res.status,
        statusText: res.statusText,
      };
    });
}

export function deleteProgramDetails(programId: number) {
  return httpClient()
    .delete(API_CONST.PROGRAM_DELETE + "/" + `${programId}`)
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error) => {
      return {
        data: error.data,
        status: error.status,
        statusText: error.statusText,
      };
    });
}

export function getAllTeamRoster(payload: any) {
  return httpIdentityClient()
    .get(
      `${API_CONST.GET_STAFF_LIST}?ProgramId=${payload.ProgramId}&SiteID=${
        payload.SiteId
      }&Id=${payload.Id}&Active=${payload.Active}&SearchField=${
        payload.SearchField
      }&SearchValue=${encodeURIComponent(payload.SearchValue)}&Search=${
        payload.Search
      }&RoleType=${payload.RoleType}&Page=${payload.Page}&Count=${
        payload.Count
      }&SortField=${payload.SortField}&SortDir=${
        payload.SortDir
      }&isExactMatch=${payload.isExactMatch}`
    )
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error) => {
      return {
        data: error.data,
        status: error.status,
        statusText: error.statusText,
      };
    });
}

export function DownloadTeamRosterCSV(payload: DownloadPayload) {
  var url = `${API_CONST.GET_DOWNLOAD_STAFFCSV}?ProgramId=${payload.programId}&SiteId=${payload.siteId}&Search=${payload.search}&SearchField=${payload.searchField}&SearchValue=${payload.searchValue}&SortField=${payload.sortField}&SortDir=${payload.sortDir}&columnsToHide=${payload.columnsToHide}&programName=${payload.programName}&siteName=${payload.siteName}`;
  return httpIdentityClient()
    .get(url)
    .then((response: any) => {
      const temp = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      let fileName =
        (response.headers["content-disposition"] &&
          response.headers["content-disposition"]
            .split(";")[1]
            .split("filename=")[1]
            .replace(/['"]+/g, "")) ||
        "download.csv";
      link.href = temp;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.target = "_blank";
      link.click();
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error: any) => {
      return {
        data: error.data,
        status: error.status,
        statusText: error.statusText,
      };
    });
}

export function sendBulkInvitation(payload: any): Promise<any> {
  return httpIdentityClient()
    .put(`${API_CONST.USERS}${API_CONST.BULK_INVITE}`, payload)
    .then((response) => {
      return {
        data: response,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error) => {
      const res = error;
      return {
        data: res.data || res.data.value || res.data,
        status: res.status,
        statusText: res.statusText,
      };
    });
}

export function sendBulkRevoke(payload: any): Promise<any> {
  return httpIdentityClient()
    .put(`${API_CONST.USERS}${API_CONST.BULK_REVOKE}`, payload)
    .then((response) => {
      return {
        data: response,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error) => {
      const res = error;
      return {
        data: res.data || res.data.value || res.data,
        status: res.status,
        statusText: res.statusText,
      };
    });
}

export function enableDisablePrograms(payload: any) {
  let encodedSearch = decodeURIComponent(payload.selectAllPayload.search);
  payload.selectAllPayload.search = encodedSearch;
  return httpClient()
    .put(
      `${API_CONST.ENABLE_DISABLE_PROGRAM}?status=${payload.status}`,
      payload.selectAllPayload
    )
    .then((response: any) => {
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error: any) => {
      return {
        data: error.data,
        status: error.status,
        statusText: error.statusText,
      };
    });
}

export function resetTaskProgram(payload: any) {
  let encodedSearch = decodeURIComponent(payload.search);
  payload.search = encodedSearch;
  return httpClient()
    .post(`${API_CONST.RESET_TASK_PROGRAM}`, payload)
    .then((response: any) => {
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error: any) => {
      return {
        data: error.data,
        status: error.status,
        statusText: error.statusText,
      };
    });
}

export function DownloadUserCSV(payload: IDownloadUsersPayload) {
  var url = `${API_CONST.GET_DOWNLOAD_USERS_CSV}?Id=${payload.id}&SearchField=${
    payload.searchField
  }&GlobalSearch=${payload.globalSearch}&&Search=${payload.search}&SortField=${
    payload.sortField
  }&SortDir=${payload.sortDir}&columnsToHide=${
    payload.columnsToHide
  }&accountName=${encodeURIComponent("" + payload.accountName)}`;
  return httpIdentityClient()
    .get(url)
    .then((response: any) => {
      const temp = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      let fileName =
        (response.headers["content-disposition"] &&
          response.headers["content-disposition"]
            .split(";")[1]
            .split("filename=")[1]
            .replace(/['"]+/g, "")) ||
        "download.csv";
      link.href = temp;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.target = "_blank";
      link.click();
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error: any) => {
      return {
        data: error.data,
        status: error.status,
        statusText: error.statusText,
      };
    });
}

export function saveBulkPrivileges(userData: any) {
  return httpIdentityClient()
    .put(`${API_CONST.BULK_PRIVILEGE}`, userData)
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error) => {
      const res = error;
      return {
        data: res.data.Message || res.data.value || res.data,
        status: res.status,
        statusText: res.statusText,
      };
    });
}

export function undoBulkPrivileges(userData: any) {
  return httpIdentityClient()
    .post(`${API_CONST.UNDO_BULK_PRIVILEGE}`, userData)
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error) => {
      const res = error;
      return {
        data: res.data.Message || res.data.value || res.data,
        status: res.status,
        statusText: res.statusText,
      };
    });
}

export function getGroupsBySiteid(payload: IGroupsBySiteid): Promise<any> {
  let highestRole: number = JSON.parse(
    JSON.stringify(APP_UTILITIES.getCookie("highest_role"))
  );
  let unassignedgroups: boolean = true;
  if (highestRole == APP_CONST.STAFF_ROLE_ID) {
    unassignedgroups = false;
  }
  return httpClient()
    .get(
      API_CONST.POST_SITE_DATA +
        "/" +
        payload.siteId +
        "/" +
        API_CONST.PROGRAM +
        payload.programId +
        "/" +
        API_CONST.CLASSROOMS +
        "?includeUnassignedScholars=" +
        unassignedgroups
    )
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error) => {
      return {
        data: error.data,
        status: error.status,
        statusText: error.statusText,
      };
    });
}

export function getAdaWeekLineData(params: IAdaWeekLinePayload) {
  return httpCMSAnalyticsClient()
    .get(
      `${API_CONST.ANALYTICS_ADA}?programId=${params.programId}&siteId=${params.siteId}&groupIds=${params.groupIds}&IsDay=${params.isDay}`
    )
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error) => {
      return {
        data: error.data,
        status: error.status,
        statusText: error.statusText,
      };
    });
}

export function bulkResetPassword(payload: any): Promise<any> {
  return httpIdentityClient()
    .put(`${API_CONST.USERS}${API_CONST.RESET_PASSWORD}`, payload)
    .then((response) => {
      return {
        data: response,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error) => {
      const res = error;
      return {
        data: res.data || res.data.value || res.data,
        status: res.status,
        statusText: res.statusText,
      };
    });
}

export function getRoleWiseUsersByProgramId(
  programIds: number[]
): Promise<any> {
  return httpIdentityClient()
    .get(
      `${API_CONST.USERS}${API_CONST.ROLEWISE_USERS}/${API_CONST.PROGRAM}/${programIds}`
    )
    .then((response) => {
      return {
        data: response,
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error) => {
      const res = error;
      return {
        data: res.data || res.data.value || res.data,
        status: res.status,
        statusText: res.statusText,
      };
    });
}


export async function guardianRevokeAccessForIdentity(guardianDto: GuardianRevokeAccessDto[]){
  return httpIdentityClient().put(`${API_CONST.GUARDIANS_REVOKE_ACCESS}`, { users: guardianDto}).then((response) => {
    return { data: response.data, status: response.status, statusText: response.statusText };
  }).catch((error) => {
    return { data: error.data, status: error.status, statusText: error.statusText };
  });
}
