










































import { LandscapeMode } from "@/popupcomponents/landscapemodepopup/LandscapeMode";
export default LandscapeMode;
