export const analyticsEventNames = {
  LOGIN: 'Login',
  HELP_CENTER_CLICKED: 'Help Center Clicked',
  LANDING_PAGE_VIEWED: 'Landing Page Viewed',
  LOGIN_PAGE_VIEWED: 'Login Page Viewed',
  DASHBOARD_VIEWED: 'Dashboard Viewed',
  PROGRAM_LIST_VIEWED: 'Program List Viewed',
  SESSION_CREATED: 'Session Created',
  PARTICIPANT_TABLE_VIEWED: 'Participant Table Viewed',
  PARTICIPANT_TABLE_SEARCHED: 'Participant Table Searched',
  PARTICIPANT_SUMMARY_FLYOUT_VIEWED: 'Participant Summary Flyout Viewed',
  FAMILY_CONTACTS_TABLE_VIEWED: 'Family Contacts Table Viewed',
  PAYMENT_SETTINGS_VIEWED: 'Payment Settings Viewed',
  SESSION_PARTICIPANT_QUICK_ADDED: 'Session Participant Quick Added',
  SUBMISSION_VIEWED: 'Submission viewed',
  SUBMISSION_PAYMENT_COMPLETED: 'Submission Payment Completed',
  ENROLLMENT_ANALYTICS_VIEWED: 'Enrollment Analytics Viewed',
  APPLICATION_ANALYTICS_VIEWED: 'Application Analytics Viewed',
  ADA_ANALYTICS_VIEWED: 'ADA Analytics Viewed',
  CONSOLIDATED_ROSTER_DOWNLOADED: 'Consolidated Roster Downloaded',
  ATTENDANCE_REPORT_DOWNLOADED: 'Attendance Report Downloaded',
  ANALYTICS_ATTENDANCE_COMPLETION_VIEWED: 'Analytics Attendance Completion Viewed',
  ANALYTICS_RETENTION_VIEWED: 'Analytics Retention Viewed',
  ATTENDANCE_PAGE_VIEWED: 'Attendance page viewed',
  ATTENDANCE_PARTICIPANT_CHECKED_IN: 'Attendance Participant Checked-in',
  ATTENDANCE_PARTICIPANT_CHECKED_OUT: 'Attendance Participant Checked-out',
  LEARNING_LAUNCHPAD_PAGE_VIEWED: 'Learning launchpad page viewed',
  LEARNING_LIBRARY_PAGE_VIEWED: 'Learning library page viewed',
  LEARNING_LIBRARY_CONTENT_ADDED_TO_A_COLLECTION: 'Learning library Content added to a collection',
  LEARNING_COURSE_LANDING_PAGE_VIEWED: 'Learning course landing page viewed',
  LEARNING_ACTIVITY_LANDING_PAGE_VIEWED: 'Learning activity landing page viewed',
  LEARNING_RESOURCE_LANDING_PAGE_VIEWED: 'Learning resource landing page viewed',
  LEARNING_STRATEGY_LANDING_PAGE_VIEWED: 'Learning strategy landing page viewed',
  LEARNING_ROUTINE_LANDING_PAGE_VIEWED: 'Learning routine landing page viewed',
  LEARNING_LEARN_ABOUT_LANDING_PAGE_VIEWED: 'Learning learn about landing page viewed',
  LEARNING_CONTENT_PREVIEW_LANDING_PAGE_VIEWED: 'Learning content preview landing page viewed',
  LEARNING_MEDIA_RESOURCE_DOWNLOADED: 'Learning media resource downloaded',
  LEARNING_VIDEO_RESOURCE_PLAYED: 'Learning video resource played',
  LEARNING_COURSE_STARTED: 'Learning course started',
  LEARNING_COURSE_REPEATED: 'Learning course repeated',
  PROGRAM_PLANNER_VIEWED: 'Program planner viewed',
  PROGRAM_PLANNER_MY_TASKS_PAGE_VIEWED: 'Program Planner My Tasks page viewed',
  PROGRAM_PLANNER_EVENTS_PAGE_VIEWED: 'Program Planner Events page viewed',
  PROGRAM_PLANNER_TEAM_ROSTER_VIEWED: 'Program Planner Team Roster viewed'
} as const;
