import { Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import jwt_decode from 'jwt-decode';

import { getInstance } from '@/auth';
import store from '@/store';
import { GlobalModule } from '@/store/global/globalModule';

const globalModule = getModule(GlobalModule, store);

export default class AUTH_UTILITIES extends Vue {

  /**
   * Time offset added to ensure that the token is still valid when it is actually used.
   * The offset is in milliseconds.
   *
   * @type {number}
   * @private
   * @readonly
   */
  private static readonly TOKEN_EXPIRATION_TIME_OFFSET: number = 5000;

  /**
   * Retrieves an authentication token from Auth0 service.
   * @returns {Promise<string>} A promise that resolves to the authentication token.
   */
  public static async getAuthTokenFromAuth0(): Promise<string> {
    const authService = getInstance();
    let token = null;

    if (authService) {
      if (!(await authService.getAuthClient())) {
        await authService.iniAuth();
      }
      token = await authService.getTokenSilently();
    }

    return token;
  }

  /**
   * Retrieves the authentication token stored in memory.
   * @returns {string | undefined} The authentication token from memory, or undefined if not found.
   */
  public static getAuthTokenFromMemory(): string | undefined {
    if (globalModule.storedAuthToken !== null) {
      return globalModule.storedAuthToken;
    }
  }

  /**
   * Sets the authentication token in memory.
   *
   * @param {string} authToken - The authentication token to be stored in memory.
   * @returns {void}
   */
  public static setAuthTokenInMemory(authToken: string): void {
    globalModule.mutateAuthToken(authToken);
  }

  /**
   * Checks if the provided authentication token is valid.
   * A valid token is one that is not undefined and has not expired.
   *
   * @param {string | undefined} authToken - The authentication token to validate.
   * @returns {boolean} True if the token is valid, false otherwise.
   */
  public static isValidAuthToken(authToken: string | undefined): boolean {
    if (!authToken) {
      return false;
    }

    const { exp } = jwt_decode(authToken) as any;
    const now = (Date.now().valueOf() / 1000) + AUTH_UTILITIES.TOKEN_EXPIRATION_TIME_OFFSET;

    return typeof exp !== 'undefined' && exp > now;
  }

  /**
   * Retrieves a valid authentication token, either from memory or by fetching a new one from Auth0.
   * @returns {Promise<string>} A promise that resolves to a valid authentication token.
   */
  public static async getValidAuthToken(): Promise<string | undefined> {
    let currentToken = AUTH_UTILITIES.getAuthTokenFromMemory();

    if (!AUTH_UTILITIES.isValidAuthToken(currentToken)) {
      // Token expired, tries to make silent authentication
      // and sets the new token in memory
      currentToken = await AUTH_UTILITIES.getAuthTokenFromAuth0();
      AUTH_UTILITIES.setAuthTokenInMemory(currentToken);
    }

    return currentToken;
  }
}
