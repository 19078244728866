import { coreIds } from "@/Model/model";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import MutationNames from "./mutationNames";

const { SUPRESS_GLOBAL_ERROR_POPUP } = MutationNames;

@Module({ namespaced: true, name: "globalModule" })
export class GlobalModule extends VuexModule {
  accountId: number = 0;
  programId: number = 0;
  groupedHeaders: any = null;
  csvHeaders: any[] = [];
  roasterDraftData: any = {};
  currnetFileName: string = "";
  isValid: boolean = false;
  availableHeaders: string[] = [];
  unmappedOptionList: string[] = [];
  customAddition: boolean = false;
  siteId: number = 0;
  autoSaveStep2: any[] = [];
  labelToFocusOn = -1;
  sites: Array<Object> = [];
  resetPageCounter: boolean = false;
  updatedSiteData: any = {};
  coreIds: coreIds[] = [
    { id: 0, programId: 0, siteId: 0, roleId: 0, accountId: 0 }
  ];
  isDeleteUserStatusActive: boolean = false;
  selectedRecordsMultiSelect: any = [];
  fewDataChecked: string = "";
  authToken: string = "";
  ldClient: any = null;

  supressGlobalErrorPopup = false;

  get storedLDClient() {
    return this.ldClient;
  }

  get storedAuthToken() {
    return this.authToken;
  }

  get fewDataCheckedDropdown() {
    return this.fewDataChecked;
  }

  get selectedRecordsMultiSelectDropdown() {
    return this.selectedRecordsMultiSelect;
  }

  get AccountId() {
    return this.accountId;
  }

  get GroupedHeaders() {
    return this.groupedHeaders;
  }

  get CsvHeaders() {
    return this.csvHeaders;
  }

  get ProgramId() {
    return this.accountId;
  }

  get getDraftData() {
    return this.roasterDraftData;
  }

  get getFileName() {
    return this.currnetFileName;
  }

  get validity2() {
    return this.isValid;
  }

  get csvUnmappedHeader() {
    return this.availableHeaders;
  }

  get unmappedList() {
    return this.unmappedOptionList;
  }

  get checkCustomAddition() {
    return this.customAddition;
  }

  get getSiteId() {
    return this.siteId;
  }

  get resetPageIndex() {
    return this.resetPageCounter;
  }

  get step2AutoSave() {
    return this.autoSaveStep2;
  }

  get labelTofocus() {
    return this.labelToFocusOn;
  }

  get getUpdatedSites() {
    return this.sites;
  }

  get getCoreIds() {
    return this.coreIds;
  }

  get getUpdatedSiteData() {
    return this.updatedSiteData;
  }

  get getDeleteUserStatus() {
    return this.isDeleteUserStatusActive;
  }

  @Mutation
  mutateLDClient(ldClient: any) {
    this.ldClient = ldClient;
  }

  @Mutation
  mutateAuthToken(authToken: string) {
    this.authToken = authToken;
  }

  @Mutation
  mutateFewDataChecked(fewDataChecked: any) {
    this.fewDataChecked = fewDataChecked;
  }

  @Mutation
  mutateSelectedRecordsMultiSelect(selectedRecordsMultiSelect: any) {
    this.selectedRecordsMultiSelect = selectedRecordsMultiSelect;
  }

  @Mutation
  mutateAccountId(id: number) {
    this.accountId = id;
  }

  @Action({ commit: "mutateAccountId" })
  assignAccountId(id: number) {
    return id;
  }

  @Mutation
  mutateGroupedHeader(headers: any[]) {
    this.groupedHeaders = headers;
  }

  @Action({ commit: "mutateGroupedHeader" })
  setGroupedHeaders(headers: any[]) {
    return headers;
  }

  @Mutation
  mutateCsvHeader(headers: any[]) {
    this.csvHeaders = headers;
  }

  @Action({ commit: "mutateCsvHeader" })
  setCsvHeaders(headers: any[]) {
    return headers;
  }

  @Mutation
  mutateRoasterDraftData(obj: any) {
    this.roasterDraftData = obj;
  }

  @Action({ commit: "mutateRoasterDraftData" })
  getRoasterDraftData(roasterDraftData: any) {
    return roasterDraftData;
  }
  @Mutation
  mutateCSVFilneName(name: any) {
    this.currnetFileName = name;
  }

  @Action({ commit: "mutateCSVFilneName" })
  setCSVFileName(name: string) {
    return name;
  }

  @Mutation
  [SUPRESS_GLOBAL_ERROR_POPUP](supressGlobalErrorPopup: boolean) {
    this.supressGlobalErrorPopup = supressGlobalErrorPopup;
  }

  @Mutation
  Step2Validity(valid: boolean) {
    this.isValid = valid;
  }

  @Action({ commit: "Step2Validity" })
  isValidStep2(valid: boolean) {
    return valid;
  }

  @Mutation
  unmatchedCsvHeaders(headers: []) {
    this.availableHeaders = headers;
  }

  @Action({ commit: "unmatchedCsvHeaders" })
  getUnmatchedHeaders(headers: []) {
    return headers;
  }

  @Mutation
  unmappedOption(headers: []) {
    this.unmappedOptionList = headers;
  }

  @Action({ commit: "unmappedOption" })
  nonMatchedOption(headers: []) {
    return headers;
  }

  @Mutation
  enableCustomAddition(value: boolean) {
    this.customAddition = value;
  }

  @Action({ commit: "enableCustomAddition" })
  enableAddition(value: boolean) {
    return value;
  }

  @Mutation
  mutateSiteId(value: number) {
    this.siteId = value;
  }

  @Action({ commit: "mutateSiteId" })
  setSiteId(value: number) {
    return value;
  }

  @Mutation
  mutatePageCounter(value: boolean) {
    this.resetPageCounter = value;
  }

  @Action({ commit: "mutatePageCounter" })
  resetPageCount(value: boolean) {
    return value;
  }

  @Mutation
  mutateupdateSiteById(value: any) {
    this.updatedSiteData = value;
  }

  @Action({ commit: "mutateupdateSiteById" })
  updateSiteById(value: any) {
    return value;
  }

  @Mutation
  mutateAutoSaveStep2(value: any[]) {
    this.autoSaveStep2 = value;
  }

  @Action({ commit: "mutateAutoSaveStep2" })
  autoSaveStep2Data(value: any) {
    return value;
  }

  @Mutation
  focusedLabel(value: number) {
    this.labelToFocusOn = value;
  }

  @Action({ commit: "focusedLabel" })
  focusOnLabel(value: number) {
    return value;
  }

  @Mutation
  sitesUpdatedInfo(sites: Array<Object>) {
    this.sites = sites;
  }

  @Action({ commit: "sitesUpdatedInfo", rawError: true })
  showUpdatedSites(sites: Array<Object>) {
    return sites;
  }

  @Mutation
  mutateCoreIds(data: coreIds[]) {
    this.coreIds = data;
  }

  @Action({ commit: "mutateCoreIds", rawError: true })
  storeCoreIds(coreIdList: coreIds[]) {
    return coreIdList;
  }

  @Mutation
  storeDeleteUserStatus(status: boolean) {
    this.isDeleteUserStatusActive = status;
  }

  @Action({ commit: "storeDeleteUserStatus", rawError: true })
  updateDeleteUserStatus(status: boolean) {
    return status;
  }
}
