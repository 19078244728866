export enum ApiErrorType {
    Error_Unknown = 'Error_Unknown',
    Error_ProgramNameExistsInAccount = 'Error_ProgramNameExistsInAccount',
    Error_SessionWasNotCreated = 'Error_SessionWasNotCreated',
    Error_SessionWasNotUpdated = 'Error_SessionWasNotUpdated'
}

export interface ApiErrorResponse {
    name: ApiErrorType;
    value: string;
    resourceNotFound: boolean;
    searchedLocation: string;
}

export type ApiErrorResponseVariant = ApiErrorResponse | { data: ApiErrorResponse | { message: ApiErrorResponse } | '' };