import { AnalyticsService } from '@/analytics';

const win: WindowWithSegmentAnalytics = window;

/**
 * Segment Analytics Service for collecting user data.
 */
export const segmentAnalyticsService: AnalyticsService = {
  identify(userId, traits): void {
    if (!win.analytics) {
      console.warn('Segment analytics not initialized. User not identified:', userId, traits);
    }
    else {
      win.analytics.identify(userId, traits);
    }
  },
  page(eventName, properties): void {
    if (!win.analytics) {
      console.warn('Segment analytics not initialized. Page not tracked:', eventName, properties);
    }
    else {
      win.analytics.page(eventName, properties);
    }
  },
  track(eventName, properties): void {
    if (!win.analytics) {
      console.warn('Segment analytics not initialized. Event not tracked:', eventName, properties);
    }
    else {
      win.analytics.track(eventName, properties);
    }
  }
};

type WindowWithSegmentAnalytics = typeof window & {
  analytics?: {
    /**
     * The Identify method lets you tie a user to their actions and record traits about them.
     * @param userId - Unique identifier for the user.
     * @param traits - Optional. Free-form dictionary of traits of the user, like `email` or `name`.
     */
    identify(userId: string, traits?: Record<string, unknown>): void;
    /**
     * The Page method lets you record whenever a user sees a page of your website, along with any optional properties about the page.
     * @param name - The name of the page.
     * @param properties - Optional. Free-form dictionary of properties of the page, like `url` and `referrer`.
     */
    page(name: string, properties?: Record<string, unknown>): void;
    /**
     * The Track method lets you record actions your users perform.
     * @param event - The name of the event you’re tracking.
     * @param properties - Optional. A dictionary of properties for the event. If the event was 'Added to Cart', it might have properties like `price` and `productType`.
     * @param options - Optional. A dictionary of options. For example, enable or disable specific destinations for the call. Note: If you do not pass a `properties` object, pass an empty object (like ‘{}’) before `options`.
     * @param callback - Optional. A function that runs after a timeout of 300 ms, giving the browser time to make outbound requests first.
     */
    track(event: string, properties?: Record<string, unknown>, options?: Record<string, unknown>, callback?: () => void): void;
  };
};
