import { getProgramsList, searchTable, getProgramListResponseObj, getaccountbyid, enableDisablePrograms, resetTaskProgram } from "@/services/userService/users-api";
import store from "@/store";
import APP_CONST from '@/constants/AppConst';

import {
  getModule,
  Module,
  VuexModule,
  MutationAction,
  Mutation,
  Action
} from "vuex-module-decorators";
import { ProgramListPayload, AccountDtById, SitesPayload, IProgramStatus } from '@/Model/model';
import APP_UTILITIES from "@/utilities/commonFunctions";
import { deleteBulkSite, getProgramDetailsById, getSiteByProgramId, getSitesByProgramId } from "@/services/create-user/createUserService";
import { getSubscriptionCategories, mapAccountCmsLicenses } from '@/utilities/cms/subscriptionCategory';

@Module({
  name: "programListStore",
  namespaced: true,
  store,
  dynamic: true
})

export class ProgramListModule extends VuexModule {
  programListPayload = APP_CONST.PROGRAM_PAYLOAD;
  programListPayloadForFullData = APP_CONST.PROGRAM_PAYLOAD_FULL;
  programListData: any = { programList: [], totalProgramsCount: APP_CONST.ZERO, totalActiveProgramsCount: APP_CONST.ZERO, responseStatus: APP_CONST.ZERO };
  programsdata: any = {};
  searchdata: any = {};
  accountDetails: any = {};
  cmsSubscriptionCategories: Array<string> = [];
  cmsLicenses: { subscriptionName: string; subscriptionGrades: string[] }[] = [];
  appSetting: any = {isLoading: true};
  programAndSiteListObj: any = [];
  progInRole = { roleId: 0, sessionStarInfos: [], programName: '' };
  showEmptyState: boolean = false;
  previousRoute: any = { title: '', returnTo: '', subHeader: false, bgColor: '' }
  programsHeader = APP_CONST.PROGRAMS_TABLE_HEADER.MOVABLE;
  sitesDisplayColumn = APP_CONST.SITES_TABLE_HEADER.MOVABLE;
  public userSettingsObj: any = {};
  public userFilteredObj: any = APP_CONST.USERS_HEADER.MOVABLE;
  public sitesData: any = { sitesList: [], count: APP_CONST.ZERO, totalActiveCount: APP_CONST.ZERO, responseStatus: APP_CONST.ZERO };
  siteListPayload: SitesPayload = APP_CONST.SITES_PAYLOAD;
  public accountData: any = {};
  public programSettingsObj: any = {};
  public programResizeData: any = [];
  public siteResizeData: any = [];
  public programResizeObj: any = [];
  public programResizableArray: any[] = [];
  public siteResizableArray: any[] = [];
  public siteSettingsObj: any = {};
  public siteResizeObj: any = [];
  public authKey: string = "";
  public selectedProgramStatus: IProgramStatus = { key: 0, name: "All Program" };
  public programDetailsData: any = {};
  public scheduleDateData: string = '';
  public programListFilter: any = []
  public addEditViewMode: string = ''
  public siteDataList: any = [];
  public programSearchObj: any = { programName: "", programType: "", noOfScholar: "", enrolledScholars: "", programStatus: "", noOfSites: "", isExactMatch: false };
  public allProgramsSearchObj: any = [];
  public allProgramSortObj: any = [];
  public sitesSearchObj: any = { siteName: "", noOfScholars: "", scholarCapacity: "", ageRestriction: "", autoEnrollment: "", waitListCapacity: "", isExactMatch: false };
  public allSiteSearchObj: any = [];
  public allSiteSortObj: any = [];
  public allProgramDataFull: any = [];

  get programIds() {
    let programIds: number[] = []
    if (this.programListFilters && this.programListFilters.length) {
      this.programListFilters.forEach((program: any) => {
        if (!programIds.includes(program.programId)) {
          programIds.push(program.programId)
        }
      })
    }
    return programIds
  }

  get programListFilters() {
    return this.programListFilter;
  }

  get programDt() {
    return this.programDetailsData;
  }
  get programList() {
    return this.programsdata;
  }

  get selectedProgramsStatus() {
    return this.selectedProgramStatus;

  }

  get accountDetail() {
    return this.accountDetails;
  }

  get programAndSiteList() {
    return this.programAndSiteListObj;
  }

  get drilledProgram() {
    return this.progInRole;
  }

  get previousRouteDetails() {
    return this.previousRoute
  }

  get programsHeaders() {
    return this.programsHeader;
  }

  get sitesDisplayColumns() {
    return this.sitesDisplayColumn;
  }

  get userFilteredObject() {
    return this.userFilteredObj;
  }

  get accountSettingsObject() {
    return this.userSettingsObj;
  }

  get programsList() {
    return this.programListData;
  }

  get programsListAllData() {
    return this.allProgramDataFull;
  }

  get allProgramIds() {
    return this.allProgramDataFull;
  }
  get currentProgramsPayload() {
    return this.programListPayload;
  }

  get currentProgramListPayloadForFullData() {
    return this.programListPayloadForFullData;
  }

  get accountInfo() {
    return this.accountData;
  }

  get sitesList() {
    return this.sitesData;
  }

  get currentSitesPayload() {
    return this.siteListPayload;
  }

  get programResizesData() {
    return this.programResizeData;
  }

  get siteResizesData() {
    return this.siteResizeData;
  }

  get addEditViewModeValue() {
    return this.addEditViewMode;
  }

  get siteDetailsList() {
    return this.siteDataList;
  }

  get programsSearchObj() {
    return this.programSearchObj;
  }

  get siteSearchObj() {
    return this.sitesSearchObj;
  }

  get cmsSubscriptionCategoryList() {
    return this.cmsSubscriptionCategories;
  }

  get cmsLicensesList() {
    return this.cmsLicenses;
  }

  @Mutation
  mutateProgramStatus(status: any) {
    this.selectedProgramStatus.name = status && status.name ? status.name : "";
    this.selectedProgramStatus.key = status && status.filter ? status.filter : "";
  }


  get scheduleDateInvitation() {
    return this.scheduleDateData;
  }


  @Mutation
  mutateProgramFilterObject(displayColumn: any) {
    this.programsHeader = displayColumn;
  }

  @Mutation
  mutateSiteFilterObject(displayColumn: any) {
    this.sitesDisplayColumn = displayColumn;
  }

  @Mutation
  mutateAllProgramListdata(payload: any) {
    this.programListPayloadForFullData = payload;
  }

  @Mutation
  mutateProgramsPayload(payload: any) {
    this.authKey = APP_UTILITIES.authTokenKeyToManage() ? APP_UTILITIES.authTokenKeyToManage() : "";
    this.programResizeData = [];
    this.programListPayload = payload;
    let accountId: any = APP_UTILITIES.getCookie('accountId');
    if (APP_UTILITIES.getFilteredColumns(`${this.authKey}-ProgramSettingsData`)) {
      let programSettingData: any = APP_UTILITIES.getFilteredColumns(`${this.authKey}-ProgramSettingsData`)
      programSettingData = JSON.parse(programSettingData)
      this.programSettingsObj = programSettingData;
      if (programSettingData.hasOwnProperty([accountId])) {
        Object.keys(programSettingData).forEach(key => {
          if (key == accountId) {
            this.programsHeader = programSettingData[key];
          }
        });
      }
    }
    if (APP_UTILITIES.getFilteredColumns(`${this.authKey}-ProgramResizeData`)) {
      let programResizeObject: any = APP_UTILITIES.getFilteredColumns(`${this.authKey}-ProgramResizeData`)
      programResizeObject = JSON.parse(programResizeObject)
      this.programResizeObj = programResizeObject;
      if (programResizeObject.hasOwnProperty([accountId])) {
        Object.keys(programResizeObject).forEach(key => {
          if (key == accountId) {
            this.programResizeData = programResizeObject[key];
          }
        });
      }
    }
    if (APP_UTILITIES.getFilteredColumns(`${this.authKey}-AllProgramSearchObj`)) {
      let allProgSearch: any = APP_UTILITIES.getFilteredColumns(`${this.authKey}-AllProgramSearchObj`);
      allProgSearch = JSON.parse(allProgSearch);
      this.allProgramsSearchObj = allProgSearch;

      if (allProgSearch.hasOwnProperty([accountId])) {
        this.programSearchObj = allProgSearch[accountId];
        let searchKeyResult = Object.keys(allProgSearch[accountId]).find(keyName => (keyName !== 'isExactMatch' && allProgSearch[accountId][keyName] != '' && allProgSearch[accountId][keyName] != null));
        this.programListPayload['searchField'] = searchKeyResult ? searchKeyResult : '';
        this.programListPayload['search'] = searchKeyResult && allProgSearch[accountId] && allProgSearch[accountId][searchKeyResult] ? allProgSearch[accountId][searchKeyResult] : '';
        this.programListPayload['isExactMatch'] = searchKeyResult && allProgSearch[accountId] && allProgSearch[accountId].isExactMatch || false;
      }
    }
    if (APP_UTILITIES.getFilteredColumns(`${this.authKey}-ProgramsSortPayload`)) {
      let progPayload: any = APP_UTILITIES.getFilteredColumns(`${this.authKey}-ProgramsSortPayload`);
      progPayload = JSON.parse(progPayload);
      this.allProgramSortObj = progPayload;
      if (progPayload.hasOwnProperty([accountId])) {
        this.programListPayload['sortField'] = progPayload[accountId].sortField ? progPayload[accountId].sortField : '';
        this.programListPayload['sortDir'] = progPayload[accountId].sortDir ? progPayload[accountId].sortDir : 0;
      }
    }
  }

  @Mutation
  mutateProgramResizeData(obj: any) {
    this.authKey = APP_UTILITIES.authTokenKeyToManage() ? APP_UTILITIES.authTokenKeyToManage() : "";
    let accountId: any = APP_UTILITIES.getCookie('accountId');
    if (!this.programResizeObj.hasOwnProperty([accountId])) {
      this.programResizableArray = [];
    } else if (this.programResizeObj.hasOwnProperty([accountId])) {
      this.programResizableArray = JSON.parse(JSON.stringify(this.programResizeObj[accountId]));
    }
    let keyIndex = this.programResizableArray.findIndex((e: { id: any; }) => e.id == obj.column.id)
    if (keyIndex > -1) {
      this.programResizableArray[keyIndex] = { id: obj.column.id, maxWidth: obj.maxWidth, minWidth: obj.minWidth }
    } else {
      this.programResizableArray.push({ id: obj.column.id, maxWidth: obj.maxWidth, minWidth: obj.minWidth })
    }
    if (!Object.keys(this.programResizeObj).length) {
      this.programResizeObj = {
        [accountId]: JSON.parse(JSON.stringify(this.programResizableArray))
      }
    }
    if (this.programResizeObj.hasOwnProperty([accountId])) {
      this.programResizeObj[accountId] = JSON.parse(JSON.stringify(this.programResizableArray))
    } else {
      let newObj = {
        [accountId]: JSON.parse(JSON.stringify(this.programResizableArray))
      }
      Object.assign(this.programResizeObj, JSON.parse(JSON.stringify(newObj)))
    }
    APP_UTILITIES.setFilteredColumns(`${this.authKey}-ProgramResizeData`, this.programResizeObj);
  }

  @Mutation
  mutateProgramListData(programsRes: any) {
    if (programsRes.status == APP_CONST.RESPONSE_200) {
      this.programListData.programList = programsRes.data.results;
      this.programListData.totalProgramsCount = programsRes.data.count;
      this.programListData.totalActiveProgramsCount = programsRes.data.totalActivePrograms;
      this.programListData.responseStatus = APP_CONST.RESPONSE_200;
    }
    else {
      this.programListData.programList = [];
      this.programListData.totalProgramsCount = 0;
      this.programListData.totalActiveProgramsCount = 0;
      this.programListData.responseStatus = APP_CONST.RESPONSE_204;
    }
  }

  @Action({ commit: 'mutateProgramListData' })
  public async getAllProgramsData(): Promise<any> {
    let response = await getProgramListResponseObj(this.programListPayload);
    return response;
  }

  @Mutation
  mutateProgramListAllData(programsRes: any) {
    this.allProgramDataFull = programsRes.data.results;
  }

  @Action({ commit: 'mutateProgramListAllData' })
  public async getFullProgramsData(): Promise<any> {
    let response = await getProgramListResponseObj(this.programListPayloadForFullData);
    return response;
  }

  @MutationAction
  getProgramList(payload: ProgramListPayload) {
    return getProgramListResponseObj(payload).then((response) => {
      return { programsdata: response };
    }, (error) => {
      return { programsdata: error };
    });
  }

  @MutationAction
  searchProgramList(search_input: string) {
    return searchTable(search_input).then((response) => {
      return { searchdata: response };
    }, (error) => {
      return { searchdata: error };
    });
  }


  @Mutation
  setCmsLicenses(list: any) {
    this.cmsLicenses = list;
  }

  @Mutation
  setCmsSubscriptions(list: any) {
    this.cmsSubscriptionCategories = list;
  }

  @Action
  public async setCmsSubscriptionData() {
    const accountId: any = APP_UTILITIES.getCookie("accountId");
    const accountInfo = await getaccountbyid({accountId});
    const subIds = accountInfo.data.cmsLicenses.map((item: any) => item.cmsSubscriptionId);
    const list = await getSubscriptionCategories(subIds);
    this.context.commit('setCmsSubscriptions', list);
    const mappedAccountCmsLicenses = await mapAccountCmsLicenses(accountInfo.data.cmsLicenses);
    this.context.commit('setCmsLicenses', mappedAccountCmsLicenses);
  }

    @Mutation
  setAppSetting(obj: any) {
    this.appSetting = obj;
  }

    @Action
  public async getAppSettings() {
        const accountId: any = APP_UTILITIES.getCookie("accountId");
        let accountInfo = await getaccountbyid({accountId});
        const settingsObj = accountInfo.data.appSetting;
        if(!accountInfo.hasOwnProperty('lx')){
          //lx was not returned on the account info object
          console.error('CMS Feature flag was not returned from the backend');
          accountInfo = {
            ...accountInfo,
            lx: false,
          };
        }
        this.context.commit('setAppSetting', {...settingsObj, isLoading: false});
  }

  @MutationAction
  accountById(payload: AccountDtById) {
    return getaccountbyid(payload).then((response) => {
      if (response.status == APP_CONST.RESPONSE_200) {
        this.accountData = response.data;
      }
      return { accountDetails: response };
    }, (error) => {
      return { accountDetails: error };
    });
  }



  @Mutation
  public setProgramAndSiteList(data: { programsList: any, progInRole: any, showEmptyState: boolean }) {
    this.programAndSiteListObj = data.programsList;
    this.progInRole = data.progInRole;
    this.showEmptyState = data.showEmptyState;
    this.programListFilter = [...data.programsList, data.progInRole]
  }
  @Action({ commit: 'setProgramAndSiteList' })
  public updateProgramAndSiteList(payload: any): Promise<Object> {
    const resultNew = async function getUserDetails(payload: any): Promise<Object> {

      const promise = getProgramsList(payload);
      const result = await promise;
      let progInRole = null, index = -1;
      let programsList: any = [];
      let showEmptyState = false;
      const { programId, roleId, siteId } = APP_UTILITIES.coreids();
      if (result.status == APP_CONST.RESPONSE_200) {
        programsList = result.data;
        if ([5, 6, 7].includes(roleId)) {
          index = programsList.findIndex((item: any) => item.programId === programId);
          try {
            [progInRole] = index >= 0 ? programsList.splice(index, 1) : null;
            const session = progInRole.sessionStarInfos.length && progInRole.sessionStarInfos.find((item: any) => item.sessionId === siteId);
            progInRole.siteName = session ? session.sessionName : '';
            progInRole['roleId'] = roleId;
          }
          catch (e) {
            console.log(e);
          }
        }

      } else if (result.status == APP_CONST.RESPONSE_204) {
        showEmptyState = true;
      }
      return { programsList, progInRole, showEmptyState };
    };
    const finalResult = resultNew(payload);
    return finalResult;
  }

  @Mutation
  mutatePreviousRoute(route: any) {
    this.previousRoute = route;
  }

  @Action({ commit: 'mutatePreviousRoute' })
  updatePreviousRoute(route: any) {
    return route;
  }

  @Mutation
  mutateSettingsData(data: any) {
    this.authKey = APP_UTILITIES.authTokenKeyToManage() ? APP_UTILITIES.authTokenKeyToManage() : "";
    let accountId;
    let userRoles: any = APP_UTILITIES.getCookie("highest_role");
    if (userRoles >= 4) {
      var role: any = APP_UTILITIES.getCookie("user_role");
      var userData = JSON.parse(role);
      accountId = userData[0].accountId;
    } else {
      accountId = APP_UTILITIES.getCookie('accountId');
    }
    if (!Object.keys(this.userSettingsObj).length) {
      this.userSettingsObj = {
        [accountId]: JSON.parse(JSON.stringify(data))
      }
    }
    if (this.userSettingsObj.hasOwnProperty([accountId])) {
      this.userSettingsObj[accountId] = JSON.parse(JSON.stringify(data))
    } else {
      let newObj = { [accountId]: JSON.parse(JSON.stringify(data)) }
      Object.assign(this.userSettingsObj, JSON.parse(JSON.stringify(newObj)))
    }
    APP_UTILITIES.setFilteredColumns(`${this.authKey}-UserSettingsData`, this.userSettingsObj);
  }

  @Mutation
  mutateProgramSettingsData(data: any) {
    this.authKey = APP_UTILITIES.authTokenKeyToManage() ? APP_UTILITIES.authTokenKeyToManage() : "";
    let accountId: any = APP_UTILITIES.getCookie('accountId');
    if (!Object.keys(this.programSettingsObj).length) {
      this.programSettingsObj = {
        [accountId]: JSON.parse(JSON.stringify(data))
      }
    }
    if (this.programSettingsObj.hasOwnProperty([accountId])) {
      this.programSettingsObj[accountId] = JSON.parse(JSON.stringify(data))
    } else {
      let newObj = {
        [accountId]: JSON.parse(JSON.stringify(data))
      }
      Object.assign(this.programSettingsObj, JSON.parse(JSON.stringify(newObj)))
    }
    APP_UTILITIES.setFilteredColumns(`${this.authKey}-ProgramSettingsData`, this.programSettingsObj);
  }

  @Mutation
  mutateUserFilteredObject(display: any) {
    this.userFilteredObj = display
  }

  @Mutation
  mutateSitesData(sitesRes: any) {
    if (sitesRes.status == APP_CONST.RESPONSE_200) {
      this.sitesData.sitesList = sitesRes.data.results;
      this.sitesData.count = sitesRes.data.count;
      this.sitesData.totalActiveCount = sitesRes.data.totalActiveCount;
      this.sitesData.responseStatus = APP_CONST.RESPONSE_200;
    } else {
      this.sitesData.sitesList = [];
      this.sitesData.count = APP_CONST.ZERO;
      this.sitesData.totalActiveCount = APP_CONST.ZERO;
      this.sitesData.responseStatus = APP_CONST.RESPONSE_204;
    }
  }

  @Action({ commit: 'mutateSitesData' })
  async getSitesByProgramId() {
    const response = await getSitesByProgramId(this.currentSitesPayload);
    return response;
  }

  @Mutation
  mutateSitesPayload(payload: SitesPayload) {
    this.authKey = APP_UTILITIES.authTokenKeyToManage() ? APP_UTILITIES.authTokenKeyToManage() : "";
    this.siteListPayload = payload;
    this.siteResizeData = [];
    let accountId: any = APP_UTILITIES.getCookie('accountId');
    let programId: any = APP_UTILITIES.getCookie('program_id');
    if (APP_UTILITIES.getFilteredColumns(`${this.authKey}-SiteSettingsData`)) {
      let siteSettingData: any = APP_UTILITIES.getFilteredColumns(`${this.authKey}-SiteSettingsData`)
      siteSettingData = JSON.parse(siteSettingData)
      this.siteSettingsObj = siteSettingData;
      if (siteSettingData.hasOwnProperty([accountId])) {
        if (siteSettingData[accountId].hasOwnProperty([programId])) {
          this.sitesDisplayColumn = siteSettingData[accountId][programId];
        }
      }
    }
    if (APP_UTILITIES.getFilteredColumns(`${this.authKey}-SiteResizeData`)) {
      let siteResizeData: any = APP_UTILITIES.getFilteredColumns(`${this.authKey}-SiteResizeData`);
      siteResizeData = JSON.parse(siteResizeData);
      this.siteResizeObj = siteResizeData;
      if (siteResizeData.hasOwnProperty([accountId])) {
        if (siteResizeData[accountId].hasOwnProperty([programId])) {
          this.siteResizeData = siteResizeData[accountId][programId];
        }
      }
    }
    if (APP_UTILITIES.getFilteredColumns(`${this.authKey}-AllSiteSearchObj`)) {
      let allSiteSearch: any = APP_UTILITIES.getFilteredColumns(`${this.authKey}-AllSiteSearchObj`);
      allSiteSearch = JSON.parse(allSiteSearch);
      this.allSiteSearchObj = allSiteSearch;

      if (allSiteSearch.hasOwnProperty([accountId]) && allSiteSearch[accountId].hasOwnProperty([programId])) {
        this.sitesSearchObj = allSiteSearch[accountId][programId];
        let searchKeyResult = Object.keys(allSiteSearch[accountId][programId]).find(keyName => (keyName !== 'isExactMatch' && allSiteSearch[accountId][programId][keyName] != '' && allSiteSearch[accountId][programId][keyName] != null));
        this.siteListPayload['searchField'] = searchKeyResult ? searchKeyResult : '';
        this.siteListPayload['search'] = searchKeyResult && allSiteSearch[accountId] && allSiteSearch[accountId][programId] && allSiteSearch[accountId][programId][searchKeyResult] ? allSiteSearch[accountId][programId][searchKeyResult] : '';
        this.siteListPayload['isExactMatch'] = searchKeyResult && allSiteSearch[accountId] && allSiteSearch[accountId][programId] && allSiteSearch[accountId][programId].isExactMatch || false;
      }
    }
    if (APP_UTILITIES.getFilteredColumns(`${this.authKey}-SitesSortPayload`)) {
      let sitePayload: any = APP_UTILITIES.getFilteredColumns(`${this.authKey}-SitesSortPayload`);
      sitePayload = JSON.parse(sitePayload);
      this.allSiteSortObj = sitePayload;
      if (sitePayload.hasOwnProperty([accountId]) && sitePayload[accountId].hasOwnProperty([programId])) {
        this.siteListPayload['sortField'] = sitePayload[accountId][programId].sortField ? sitePayload[accountId][programId].sortField : '';
        this.siteListPayload['sortDir'] = sitePayload[accountId][programId].sortDir ? sitePayload[accountId][programId].sortDir : 0;
      }
    }
  }

  @Mutation
  mutateSiteSettingsData(data: any) {
    this.authKey = APP_UTILITIES.authTokenKeyToManage() ? APP_UTILITIES.authTokenKeyToManage() : "";
    let accountId: any = APP_UTILITIES.getCookie('accountId');
    let programId: any = APP_UTILITIES.getCookie('program_id');
    if (!Object.keys(this.siteSettingsObj).length) {
      this.siteSettingsObj = {
        [accountId]: {
          [programId]: JSON.parse(JSON.stringify(data))
        }
      }
    }
    if (this.siteSettingsObj.hasOwnProperty([accountId]) && this.siteSettingsObj[accountId].hasOwnProperty([programId])) {
      this.siteSettingsObj[accountId][programId] = JSON.parse(JSON.stringify(data));
    } else if (this.siteSettingsObj.hasOwnProperty([accountId]) && !this.siteSettingsObj[accountId].hasOwnProperty([programId])) {
      let siteTable = {
        [programId]: JSON.parse(JSON.stringify(data))
      }
      Object.assign(this.siteSettingsObj[accountId], JSON.parse(JSON.stringify(siteTable)))
    } if (!this.siteSettingsObj.hasOwnProperty([accountId])) {
      let allTable = {
        [accountId]: {
          [programId]: JSON.parse(JSON.stringify(data))
        }
      }
      Object.assign(this.siteSettingsObj, JSON.parse(JSON.stringify(allTable)))
    }
    APP_UTILITIES.setFilteredColumns(`${this.authKey}-SiteSettingsData`, this.siteSettingsObj);
  }

  @Mutation
  mutateSiteResizeData(obj: any) {
    this.authKey = APP_UTILITIES.authTokenKeyToManage() ? APP_UTILITIES.authTokenKeyToManage() : "";
    let accountId: any = APP_UTILITIES.getCookie('accountId');
    let programId: any = APP_UTILITIES.getCookie('program_id');
    if (!this.siteResizeObj.hasOwnProperty([accountId])) {
      this.siteResizableArray = [];
    } else if (this.siteResizeObj[accountId].hasOwnProperty([programId])) {
      this.siteResizableArray = JSON.parse(JSON.stringify(this.siteResizeObj[accountId][programId]));
    }
    let keyIndex = this.siteResizableArray.findIndex((e: { id: any; }) => e.id == obj.column.id)
    if (keyIndex > -1) {
      this.siteResizableArray[keyIndex] = { id: obj.column.id, maxWidth: obj.maxWidth, minWidth: obj.minWidth }
    } else {
      this.siteResizableArray.push({ id: obj.column.id, maxWidth: obj.maxWidth, minWidth: obj.minWidth })
    }
    if (!Object.keys(this.siteResizeObj).length) {
      this.siteResizeObj = {
        [accountId]: {
          [programId]: JSON.parse(JSON.stringify(this.siteResizableArray))
        }
      }
    }
    if (this.siteResizeObj.hasOwnProperty([accountId]) && this.siteResizeObj[accountId].hasOwnProperty([programId])) {
      this.siteResizeObj[accountId][programId] = JSON.parse(JSON.stringify(this.siteResizableArray))
    } else if (this.siteResizeObj.hasOwnProperty([accountId]) && !this.siteResizeObj[accountId].hasOwnProperty([programId])) {
      let siteTable: any = {
        [programId]: JSON.parse(JSON.stringify(this.siteResizableArray))
      }
      Object.assign(this.siteResizeObj[accountId], JSON.parse(JSON.stringify(siteTable)));
    } else if (!this.siteResizeObj.hasOwnProperty([accountId])) {
      let programSiteTable: any = {
        [accountId]: {
          [programId]: JSON.parse(JSON.stringify(this.siteResizableArray))
        }
      }
      Object.assign(this.siteResizeObj, JSON.parse(JSON.stringify(programSiteTable)))
    }
    APP_UTILITIES.setFilteredColumns(`${this.authKey}-SiteResizeData`, this.siteResizeObj);
  }

  @Mutation
  mutateProgramDetails(data: any) {
    this.programDetailsData = data;
  }

  @Mutation
  mutateScheduleDate(date: string) {
    this.scheduleDateData = date;
  }

  @Mutation
  mutateAddEditViewMode(type: string) {
    this.addEditViewMode = type
  }

  @Mutation
  mutateSiteList(siteList: any) {
    this.siteDataList = siteList;
  }

  @Action
  async enableDisableProgram(payload: any) {
    let response = await enableDisablePrograms(payload);
    return response;
  }

  @Action
  async resetTaskProgram(payload: any) {
    let response = await resetTaskProgram(payload);
    return response;
  }

  @Action
  async deleteBulkSite(payload: any) {
    let response = await deleteBulkSite(payload);
    return response;
  }

  @Action
  async getProgramDetailsById(programId: any) {
    let response = await getProgramDetailsById(programId);
    this.mutateProgramDetails(response.data);
    return response;
  }

  @Action({ commit: 'mutateSiteList' })
  public async getSiteList(payload: number): Promise<{ data: [], status: number, statusText: string }> {
    let promise = getSiteByProgramId(payload);
    let result = await promise;
    return result as { data: [], status: number, statusText: string };
  }

  @Mutation
  mutateSortPayload(data: any) {
    this.authKey = APP_UTILITIES.authTokenKeyToManage() ? APP_UTILITIES.authTokenKeyToManage() : "";
    let accountId: any = APP_UTILITIES.getCookie('accountId');

    let payload = { ...this.allProgramSortObj, [accountId]: JSON.parse(JSON.stringify(data)) }

    APP_UTILITIES.setFilteredColumns(`${this.authKey}-ProgramsSortPayload`, payload);

  }

  @Mutation
  mutateCurrentObj(data: any) {
    this.programSearchObj = data;
  }

  @Mutation
  mutateProgramsSearchObj(data: any) {
    this.authKey = APP_UTILITIES.authTokenKeyToManage() ? APP_UTILITIES.authTokenKeyToManage() : "";
    let accountId: any = APP_UTILITIES.getCookie('accountId');
    this.programSearchObj = data;
    this.allProgramsSearchObj = { ...this.allProgramsSearchObj, [accountId]: JSON.parse(JSON.stringify(data)) };

    APP_UTILITIES.setFilteredColumns(`${this.authKey}-AllProgramSearchObj`, this.allProgramsSearchObj);
  }

  @Mutation
  mutateSiteSortPayload(data: any) {
    this.authKey = APP_UTILITIES.authTokenKeyToManage() ? APP_UTILITIES.authTokenKeyToManage() : "";
    let accountId: any = APP_UTILITIES.getCookie('accountId');
    let programId: any = APP_UTILITIES.getCookie('program_id');
    let payload: any = {};

    let payloadAcc = { ...this.allSiteSortObj, [accountId]: this.allSiteSortObj[accountId] ? this.allSiteSortObj[accountId] : {} };
    let payloadPro = { ...payloadAcc[accountId], [programId]: JSON.parse(JSON.stringify(data)) };
    payload = { ...this.allSiteSortObj, [accountId]: payloadPro };

    APP_UTILITIES.setFilteredColumns(`${this.authKey}-SitesSortPayload`, payload);
  }

  @Mutation
  mutateSiteCurrentObj(data: any) {
    this.sitesSearchObj = data;
  }

  @Mutation
  mutateSitesSearchObj(data: any) {
    this.authKey = APP_UTILITIES.authTokenKeyToManage() ? APP_UTILITIES.authTokenKeyToManage() : "";
    let accountId: any = APP_UTILITIES.getCookie('accountId');
    let programId: any = APP_UTILITIES.getCookie('program_id');
    this.sitesSearchObj = data;
    let payloadAcc = { ...this.allSiteSearchObj, [accountId]: this.allSiteSearchObj[accountId] ? this.allSiteSearchObj[accountId] : {} };
    let payloadPro = { ...payloadAcc[accountId], [programId]: JSON.parse(JSON.stringify(data)) };
    this.allSiteSearchObj = { ...this.allSiteSearchObj, [accountId]: payloadPro };
    APP_UTILITIES.setFilteredColumns(`${this.authKey}-AllSiteSearchObj`, this.allSiteSearchObj);
  }
}
export default getModule(ProgramListModule);
