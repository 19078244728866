export enum Day {
    SUNDAY,
    MONDAY,
    TUESDAY,
    WEDNESDAY,
    THURSDAY,
    FRIDAY,
    SATURDAY
}

export interface DayAbbreviation {
    day: Day;
    abbreviation: string;
}