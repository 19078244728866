import { AppRoute } from '@/router';
import VueRouter from 'vue-router';
import { AnalyticsService } from '.';

/**
 * Sets up route-based analytics tracking middleware for Vue Router.
 * @param {VueRouter} router - The Vue Router instance.
 * @param {AnalyticsService} analyticsService  - The analytics service to use for tracking.
 */
export function routeAnalyticsMiddleware(router: VueRouter, analyticsService: AnalyticsService): void {
  router.afterEach((to: AppRoute) => {
    if (!to.meta || !to.meta.analytics || !to.meta.analytics.eventName) {
      return;
    }

    const { eventName, properties } = to.meta.analytics;
    analyticsService.page(eventName, properties);
  });
}
