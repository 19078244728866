




















































import FooterComponent from "@/commoncomponents/footercomponent/FooterComponent";
export default FooterComponent;
