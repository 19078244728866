import store from "@/store";
import { assign, skip, tasks, TaskSiteAssignDto, ISkipTaskDto, TaskDto, IGetTaskDto, events, addEvent, updateEvent, getSitesInfoList } from "@/services/program/plannerService";
import { getAllTeamRoster } from "@/services/userService/users-api";

import {
  getModule,
  Module,
  VuexModule,
  Mutation,
  Action
} from "vuex-module-decorators";
import { AddSiteDTO } from '@/services/program/adminService';
import APP_CONST from "@/constants/AppConst";
import APP_UTILITIES from "@/utilities/commonFunctions";
@Module({
  namespaced: true,
  name: "taskPlanner",
  store,
  dynamic: true
})
class TaskPlannerModule extends VuexModule {

  public assignTasksData: any = {};
  public isLoading: boolean = false;
  public skipTasksData: any = {};
  protected taskList: TaskDto[] | [] = [];
  eventList: any = [];
  public updatedEventResData: any = {};
  public updatedEventRes: any = {};
  public assignedSitesList: any = [];
  public teamRosterResponseStatusCode: number = 0;
  public teamRosterData: any = [];
  public teamRosterCompletedata: any = {};
  public teamrosterdatacount: number = 0;
  public programIdroster = APP_UTILITIES.getCookie("programId");
  public siteIdroster = APP_UTILITIES.getCookie("siteId");
  public rosterPayloadStore = APP_CONST.TEAM_ROSTER_PAYLOAD;
  public teamRosterFilteredObj: any = APP_CONST.TEAM_ROSTER_HEADER.MOVABLE;
  public settingsObj:any = {};
  public resizableArray: any[] = [];
  public resizeObj:any = {};
  public teamRosterResizeData:any[] = []
  public authKey: string = "";
  public teamRosterPayload=APP_CONST.TEAM_ROSTER_PAYLOAD
  public initialTotalItems: number = 0;
  public downloadState:boolean = false;

  siteId: number = 0;
  statusList: number[] = [1];
  sites: any = [];
  calendarDataVisible: boolean = false;
  sidePanelActivities: any = {
    toggleEllipseStatus: false,
    isShowAllSitesActive: false,
    selectedTabEvent: false,
    listViewType: 'list',
    isListViewActive: true,
    isProgramAdmin: false
  };
  activeTab: string = "myTasks";
  durationView: string = "months";
  eventSortingFields: any = {};
  copyStatus: boolean = false;
  public multiSelectSitesDropdown = {label: 'Select Site/Session', id: 0, value: 'Select', required: false, disable: false, search : false, placeholder : 'Search', dropdownList: []  , checked:false, isAllSelect:false, selectDropdownCount:true};

  public allTeamRosterSearchObj:any = [];
  public allTeamRosterSortObj:any = [];

  get isCalendarDataVisible() {
    return this.calendarDataVisible;
  }

  get getViewType() {
    return this.sidePanelActivities.listViewType;
  }

  get getIsListViewActive() {
    return this.sidePanelActivities.isListViewActive;
  }

  get selectedTabEvent() {
    return this.sidePanelActivities.selectedTabEvent;
  }

  get toggleEllipseStatus() {
    return this.sidePanelActivities.toggleEllipseStatus;
  }

  get isShowAllSitesActive() {
    return this.sidePanelActivities.isShowAllSitesActive;
  }
  get tasksList() {
    return this.taskList;
  }
  get eventsList() {
    return this.eventList;
  }
  get getActiveTab() {
    return this.activeTab;
  }
  get getSiteId() {
    return this.siteId;
  }
  get getStatusList() {
    return this.statusList;
  }
  get EventAddResponse() {
    return this.updatedEventResData;
  }
  get sitesList() {
    return this.sites;
  }
  get UpdatedEvent() {
    return this.updatedEventRes;
  }
  get isProgramAdmin() {
    return this.sidePanelActivities.isProgramAdmin;
  }
  get getDurationView() {
    return this.durationView;
  }

  get getEventSortingFields() {
    return this.eventSortingFields
  }

  get getCopyEventStatus() {
    return this.copyStatus;
  }

  get getteamRosterList() {
    return this.teamRosterData;
  }

  get getTeamRosterResponseStatusCode() {
    return this.teamRosterResponseStatusCode;
  }

  get getteamRosterCompletedata() {
    return this.teamRosterCompletedata;
  }

  get getteamrosterdatacount() {
    return this.teamrosterdatacount;
  }

  get teamRosterFilteredObject() {
    return this.teamRosterFilteredObj;
  }

  get teamRosterResizeArray() {
    return this.teamRosterResizeData;
  }

  get getRosterPayloadStore(){
    return this.rosterPayloadStore;
  }

  get getMultiSelectSitesDropdown(){
    return this.multiSelectSitesDropdown;
  }

  get allTeamRostersSortObj(){
    return this.allTeamRosterSortObj;
  }

  get allTeamRostersSearchObj(){
    return this.allTeamRosterSearchObj;
  }

  get initialTeamRecords(){
    return this.initialTotalItems;
  }

  get downloadEventState(){
    return this.downloadState;
  }

  @Mutation
  mutateSites(multiSelectSitesDropdown:any){
    this.multiSelectSitesDropdown = multiSelectSitesDropdown;
  }

  @Mutation
  public rosterPayloadMut(payload:any){
    this.rosterPayloadStore = payload
  }

  @Mutation
  public postAssignTasks(payload: any) {
    this.assignTasksData = payload
  }
  @Action({ commit: "postAssignTasks" })
  public async assignTasks(payload: TaskSiteAssignDto): Promise<Object> {
    let promise = assign(payload);
    let result = await promise;
    return result as any;
  }

  @Mutation
  protected getTasks(result: { data: TaskDto[] | [], status?: number, statusText?: string }) {
    const list = result.data || [];
    list && list.forEach((el: TaskDto) => {
      el.checked = false;
    })
    this.taskList = list;
  }

  @Action({ commit: 'getTasks' })
  public async tasks(payload: IGetTaskDto): Promise<{ data: [], status: number, statusText: string }> {
    let promise = tasks(payload);
    let result = await promise;
    return result as { data: [], status: number, statusText: string };
  }

  @Mutation
  protected updateTasks(list: TaskDto[] | []) {
    this.taskList = list;
  }

  @Action({ commit: 'updateTasks' })
  public taskSelection(listToSelect: { taskList: TaskDto[] | [], selectAll?: boolean | null | undefined, task?: { id: number, checked: boolean } }) {
    const list = listToSelect.taskList || [];
    if (listToSelect.selectAll) {
      list && list.forEach((el: TaskDto) => { el.checked = true })
      return list;
    } else if (listToSelect.selectAll === false) {
      list && list.forEach((el: TaskDto) => { el.checked = false })
      return list;
    } else if (listToSelect && listToSelect.task && listToSelect.task.id) {
      const id = listToSelect.task.id;
      const index = list.length && list.findIndex((el: TaskDto) => el.id === id);
      list[index].checked = listToSelect.task.checked;
      return list;
    }
  }
  @Action({ commit: 'updateSites' })
  public siteSelection(listToSelect: { siteList: AddSiteDTO[] | [], selectAll?: boolean | null | undefined, site?: { siteId: number, checked: boolean } }) {
    const list = listToSelect.siteList || [];
    if (listToSelect.selectAll) {
      list && list.forEach((el: AddSiteDTO) => { el.checked = true })
      return list;
    } else if (listToSelect.selectAll === false) {
      list && list.forEach((el: AddSiteDTO) => { el.checked = false })
      return list;
    } else if (listToSelect && listToSelect.site && listToSelect.site.siteId) {
      const siteId = listToSelect.site.siteId;
      const index = list.length && list.findIndex((el: AddSiteDTO) => el.siteId === siteId);
      list[index].checked = listToSelect.site.checked;
      return list;
    }
  }

  @Mutation
  protected getEvents(result: { data: any | [], status?: number, statusText?: string }) {
    const list = result.data && result.data.eventHolidayDtos || [];
    this.eventList = list;
  }

  @Mutation
  public updateEventData(eventList: any) {
    this.eventList = [...this.eventList, eventList];
  }
  @Mutation
  public emptyEventData(eventList: any) {
    this.eventList = eventList;
  }
  @Mutation
  public updateEventListData(eventList: any) {
    const list = eventList || [];
    this.eventList = list
  }

  @Action({ commit: 'getEvents' })
  public async events(payload: any): Promise<{ data: [], status: number, statusText: string }> {
    let promise = events(payload);
    let result = await promise;
    return result as { data: [], status: number, statusText: string };
  }

  @Mutation
  protected setSiteId(siteId: number) {
    this.siteId = siteId;
  }

  @Action({ commit: 'setSiteId' })
  public updateSiteId(siteId: number) {
    return siteId;
  }

  @Mutation
  protected setStatusList(statusList: number[]) {
    this.statusList = statusList;
  }

  @Action({ commit: 'setStatusList' })
  public updateStatusList(statusList: number[]) {
    return statusList;
  }

  @Mutation
  protected setSites(sites: any[]) {
    this.sites = sites.filter((site) => {
      return site.status == 1;
    });
  }

  @Action({ commit: 'setSites' })
  public updateSitesData(sites: any[]) {
    return sites;
  }

  @Action({ commit: 'emptyEventData' })
  public emptyEventList(eventList: any) {
    return eventList;
  }
  @Action({ commit: 'updateEventData' })
  public updateEventList(eventList: any) {
    return eventList;
  }
  @Action({ commit: 'updateEventListData' })
  public updateEventListStoreData(eventObj: any) {
    let eventIndex = this.eventsList.findIndex((obj: any) => obj.id == eventObj.id)
    this.eventsList[eventIndex] = { ...eventObj }
    return [...this.eventsList];
  }
  @Mutation
  public addEventUpdate(payload: any) {
    this.updatedEventResData = payload
  }
  @Action({ commit: "addEventUpdate" })
  public async addProgramEvent(payload: any): Promise<Object> {
    let promise = addEvent(payload);
    let result = await promise;
    return result as object;
  }
  @Action({ commit: "UpdatePorgramEvent" })
  public async updateProgramEventData(payload: any): Promise<Object> {
    let promise = updateEvent(payload, payload.id);
    let result = await promise;
    return result as object;
  }



  @Mutation
  public UpdatePorgramEvent(payload: any) {
    this.updatedEventRes = payload
  }

  @Mutation
  public setActiveTab(activeTab: string) {
    this.activeTab = activeTab
  }
  @Action({ commit: "setActiveTab" })
  public updateActiveTab(activeTab: any) {
    return activeTab;
  }

  @Mutation
  public setSidePanelActivities(payload: any) {
    switch (payload.activity) {
      case 'showHideSkipStatus':
        this.sidePanelActivities.toggleEllipseStatus = payload.value;
        break;
      case 'allSitesShowStatus':
        this.sidePanelActivities.isShowAllSitesActive = payload.value;
        break;
      case 'eventTabUpdateStatus':
        this.sidePanelActivities.selectedTabEvent = payload.value;
        break;
      case 'listViewType':
        this.sidePanelActivities.listViewType = payload.value;
        break;
      case 'isListViewActive':
        this.sidePanelActivities.isListViewActive = payload.value;
        break;
      case 'isProgramAdmin':
        this.sidePanelActivities.isProgramAdmin = payload.value;
        break;
      default:
        break;
    }
  }

  @Mutation
  public resetTeamRosterResponseStatusCode() {
    this.teamRosterResponseStatusCode = 0;
  }
  @Action({ commit: "resetTeamRosterResponseStatusCode" })
  public initlializeTeamRosterResponseStatusCode() {
    return this.teamRosterResponseStatusCode;
  }

  @Action({ commit: "setSidePanelActivities" })
  public updateSidePanelActivities(payload: any) {
    return payload;
  }

  @Mutation
  protected getNotifiedSitesList(result: { data: AddSiteDTO[] | [] }) {
    const list = result.data || [];
    this.assignedSitesList = list;
  }

  @Action({ commit: 'getNotifiedSitesList' })
  public async getNotifiedSites(programId: number): Promise<{ data: [], status: number, statusText: string }> {
    let promise = getSitesInfoList(programId);
    let result = await promise;
    return result as { data: [], status: number, statusText: string };
  }

  @Mutation
  public setCalendarDataStatus(isCalendarDataVisible: boolean) {
    this.calendarDataVisible = isCalendarDataVisible
  }
  @Action({ commit: "setCalendarDataStatus" })
  public updateCalendarDataStatus(isCalendarDataVisible: boolean) {
    return isCalendarDataVisible;
  }

  @Mutation
  public postSkipTasks(payload: ISkipTaskDto) {
    this.skipTasksData = payload
  }
  @Action({ commit: "postSkipTasks" })
  public async skipTasks(payload: ISkipTaskDto): Promise<Object> {
    let promise = skip(payload);
    let result = await promise;
    return result as any;
  }

  @Mutation
  public updateDurationView(view: string) {
    this.durationView = view
  }
  @Action({ commit: "updateDurationView" })
  public async changeDurationView(view: string): Promise<Object> {
    return view;
  }

  @Mutation
  public updateEventSortingFields(payload: any) {
    this.eventSortingFields = payload
  }

  @Action({ commit: 'updateEventSortingFields' })
  public updateEventSortingData(payload: any) {
    return payload;
  }

  @Mutation
  public updateCopyEventStatus(status: any) {
    this.copyStatus = status
  }

  @Action({ commit: 'updateCopyEventStatus' })
  public copyEventStatus(status: any) {
    return status;
  }

  @Mutation
  public teamRosterListmut(payload: any) {
    this.teamRosterResponseStatusCode=payload.status;

    if (payload.data) {
      let programIdroster = APP_UTILITIES.getCookie("programId");
      let siteIdroster = APP_UTILITIES.getCookie("siteId");
      payload.data
        && payload.data.results
        && payload.data.results.length
        && payload.data.results.forEach((element: any) => {
          if (element.staffUser && element.staffUser.firstName) {
            element['firstName'] = element.staffUser.firstName;
          }
          if (element.staffUser && element.staffUser.lastName) {
            element['lastName'] = element.staffUser.lastName;
          }
          if (element.staffUser && element.staffUser.userRoles) {
            element.staffUser.userRoles.forEach((elem: any) => {
              if (elem.programId == programIdroster) {
                let systemRole = elem.roleId
                switch (systemRole) {
                  case APP_CONST.ROLE_TYPE_ACCOUNT_ADMIN:
                    element['systemRole'] = APP_CONST.ACCOUNT_ADMIN;
                    break;
                  case APP_CONST.ROLE_TYPE_PROGRAM_ADMIN:
                    element['systemRole'] = APP_CONST.PROGRAAM_ADMIN;
                    break;
                  case APP_CONST.ROLE_TYPE_SESSION_ADMIN:
                    element['systemRole'] = APP_CONST.SESSION_ADMIN;
                    break;
                  case APP_CONST.ROLE_TYPE_STAFF:
                    element['systemRole'] = APP_CONST.STAFF;
                    break;
                  case APP_CONST.ROLE_TYPE_NONSYSTEM:
                    element['systemRole'] = APP_CONST.NO_ACCESS;
                    break;
                }
                if (elem.programRole) {
                  element['position'] = elem.programRole;
                }
                if (elem.programName) {
                  element['program'] = elem.programName;
                }

              }

              if (Number(programIdroster) != 0 && Number(siteIdroster) == 0) {
                let programSites: any = []
                if (element['systemRole'] == APP_CONST.SESSION_ADMIN || element['systemRole'] == APP_CONST.STAFF || element['systemRole'] == APP_CONST.NO_ACCESS) {
                  element.staffUser.userRoles.forEach((elm: any) => {
                    if (elm.programId == programIdroster && elm.siteName) {
                      programSites.push(elm.siteName)
                    }
                  })
                  if(this.teamRosterPayload && this.teamRosterPayload.SearchField && this.teamRosterPayload.SearchField=='sitesessions'){
                    let searchSite=decodeURI(this.teamRosterPayload.SearchValue)
                    let programsites = programSites.length - 1
                    programSites.forEach((site: string)=>{
                      if(site.toLowerCase().includes(searchSite.toLowerCase())){
                        if(programSites.length > 1){
                          element['sitesessions'] = site + " +"+ programsites;
                        }
                        else if(programSites.length == 1){
                          element['sitesessions'] = site;
                        }
                      }
                    })
                  }else{
                    if (programSites.length > 1) {
                      let programsite = programSites[0]
                      let programsites = programSites.length - 1
                      element['sitesessions'] = programsite + "   " + APP_CONST.ADD_SIGN + programsites  
                    
                    } else {
                      element['sitesessions'] = programSites[0];
                    }
                  }
                }
              }
              if (elem.programId == programIdroster && elem.siteId == siteIdroster) {
                if (elem.siteName) {
                  element['sitesessions'] = elem.siteName;
                }
              }

            })
          }
          let staffUserPhoneNumber:any = APP_CONST.BLANK_PHONE_NUMBER;
          let phoneType: string = '';
          if (element.staffUser && element.staffUser.phoneNumber) {
            staffUserPhoneNumber = APP_UTILITIES.formatNumber(element.staffUser.phoneNumber)
            element['phone'] = element.staffUser.phoneNumber;
          }
          if (element.staffUser && element.staffUser.phoneType) {
            element['phoneType'] = element.staffUser.phoneType;
            phoneType = element.staffUser.phoneType;
          }
          element['phoneNumber'] = staffUserPhoneNumber + ' ' + phoneType;
          if (element.staffUser && element.staffUser.email) {
            element['email'] = element.staffUser.email;
          }
          if(element.staffUser && element.staffUser.inviteStatus){
            element['inviteStatus'] = element.staffUser.inviteStatus;
          }
        });
      this.teamRosterData = payload.data.results
      this.teamRosterCompletedata = payload
      this.teamrosterdatacount = payload.data.count
      if(this.initialTotalItems == APP_CONST.ZERO){
      this.initialTotalItems = this.teamrosterdatacount;
      }

    } else {

      this.teamRosterData = []
      this.teamRosterCompletedata = {}
      this.teamrosterdatacount = 0
    }

  }

  @Action({ commit: "teamRosterListmut" })
  public async teamRosterList(payload: any) {
    let promise = getAllTeamRoster(payload);
    let result = await promise;
    this.mutatePayloadTeamRoster(payload)
    return result;
  }

  @Mutation
  mutatePayloadTeamRoster(payload:any){
    this.teamRosterPayload=payload
  }

  @Mutation
  mutateTeamRosterFilteredObject(display: any) {
    this.teamRosterFilteredObj = display
  }

  @Mutation
  mutateSettingsData(data: any) {
    let programId : any = APP_UTILITIES.getCookie('programId')
      programId =JSON.parse(programId)
      if(APP_UTILITIES.getCookie('siteId')){
        var siteId : any = APP_UTILITIES.getCookie('siteId')
        siteId =JSON.parse(siteId)
      }
      else{
        var siteId:any=0
      }
      if(!Object.keys(this.settingsObj).length){
      this.settingsObj = {
          [programId]:{
            [siteId] : JSON.parse(JSON.stringify(data))
        }
    } 
  }
  this.authKey = APP_UTILITIES.authTokenKeyToManage() ? APP_UTILITIES.authTokenKeyToManage() : "";
      if(this.settingsObj.hasOwnProperty([programId]) && this.settingsObj[programId].hasOwnProperty([siteId])){
          this.settingsObj[programId][siteId] = JSON.parse(JSON.stringify(data))
      }else if(this.settingsObj.hasOwnProperty([programId]) && !this.settingsObj[programId].hasOwnProperty([siteId]))
       {
        let siteData : any ={
              [siteId] : JSON.parse(JSON.stringify(data))
          }
        Object.assign(this.settingsObj[programId], JSON.parse(JSON.stringify(siteData)))
      }
      else if(!this.settingsObj.hasOwnProperty([programId])) {
        let programData : any ={
          [programId]:{
            [siteId]: JSON.parse(JSON.stringify(data))
          }
        }
        Object.assign(this.settingsObj, JSON.parse(JSON.stringify(programData)))
      }
      APP_UTILITIES.setFilteredColumns(`${this.authKey}-teamRosterSettingsData`,this.settingsObj)
  }

  @Mutation
  mutateResizeData(obj: { column: any; maxWidth: string; minWidth: string; }) {
    let programId : any = APP_UTILITIES.getCookie('programId')
    programId =JSON.parse(programId)
    if(APP_UTILITIES.getCookie('siteId')){
      var siteId : any = APP_UTILITIES.getCookie('siteId')
      siteId =JSON.parse(siteId)
    }
    else{
      var siteId:any=0
    }

    if(!this.resizeObj.hasOwnProperty([programId])){
      this.resizableArray=[]
    }
    else if(this.resizeObj.hasOwnProperty([programId])){
      if(this.resizeObj[programId].hasOwnProperty([siteId])){
        this.resizableArray=this.resizeObj[programId][siteId]
      }
      else{
      this.resizableArray=[]
      }
    }

    let keyIndex = this.resizableArray.findIndex((e: { id: any; }) => e.id == obj.column.id)
    if (keyIndex > -1) {
      this.resizableArray[keyIndex] = { id : obj.column.id, maxWidth : obj.maxWidth,minWidth : obj.minWidth }
    } else {
      this.resizableArray.push({ id: obj.column.id, maxWidth : obj.maxWidth,minWidth : obj.minWidth })
    }
    if (!Object.keys(this.resizeObj).length) {
      this.resizeObj = {
        [programId]: {
          [siteId]: JSON.parse(JSON.stringify(this.resizableArray))
        }
      }
    }
    if (this.resizeObj.hasOwnProperty([programId]) && this.resizeObj[programId].hasOwnProperty([siteId])) {
      this.resizeObj[programId][siteId] = JSON.parse(JSON.stringify(this.resizableArray))
    }
     else if (this.resizeObj.hasOwnProperty([programId]) && !this.resizeObj[programId].hasOwnProperty([siteId])) 
    {
      let siteData: any = {
        [siteId]: JSON.parse(JSON.stringify(this.resizableArray))
      }
      Object.assign(this.resizeObj[programId], JSON.parse(JSON.stringify(siteData)))
    } else if (!this.resizeObj.hasOwnProperty([programId])) {
      let programData: any = {
        [programId]: {
          [siteId]: JSON.parse(JSON.stringify(this.resizableArray))
        }
      }
      Object.assign(this.resizeObj, JSON.parse(JSON.stringify(programData)))
    } 

    APP_UTILITIES.setFilteredColumns(`${this.authKey}-teamRosterResizeData`, this.resizeObj)
  }

  @Mutation
  getPersistenceData(){
    let programId : any = APP_UTILITIES.getCookie('programId')
    programId=JSON.parse(programId)
    if(APP_UTILITIES.getCookie('siteId')){
      var siteId : any = APP_UTILITIES.getCookie('siteId')
      siteId =JSON.parse(siteId)
    }
    else{
      var siteId:any=0
    }
  this.authKey = APP_UTILITIES.authTokenKeyToManage() ? APP_UTILITIES.authTokenKeyToManage() : "";
    if (APP_UTILITIES.getFilteredColumns(`${this.authKey}-teamRosterSettingsData`)) {
      let teamRosterSettingsData:any=APP_UTILITIES.getFilteredColumns(`${this.authKey}-teamRosterSettingsData`)
      teamRosterSettingsData = JSON.parse(teamRosterSettingsData)
        if(teamRosterSettingsData.hasOwnProperty([programId]) && teamRosterSettingsData[programId].hasOwnProperty([siteId])){
            this.teamRosterFilteredObj = teamRosterSettingsData[programId][siteId]
        }
        else{
            this.teamRosterFilteredObj =JSON.parse(JSON.stringify(APP_CONST.TEAM_ROSTER_HEADER.MOVABLE))
        }
      }
    else{
      this.teamRosterFilteredObj =JSON.parse(JSON.stringify(APP_CONST.TEAM_ROSTER_HEADER.MOVABLE))
    }

    this.teamRosterResizeData=[]
    if (APP_UTILITIES.getFilteredColumns(`${this.authKey}-teamRosterResizeData`)) {
      let teamRosterResizeObj:any=APP_UTILITIES.getFilteredColumns(`${this.authKey}-teamRosterResizeData`)
      teamRosterResizeObj = JSON.parse(teamRosterResizeObj)
      this.resizeObj=teamRosterResizeObj
      if(teamRosterResizeObj.hasOwnProperty([programId]) && teamRosterResizeObj[programId].hasOwnProperty([siteId])){
          this.teamRosterResizeData = teamRosterResizeObj[programId][siteId]
      }
    }
  }

  @Mutation
  mutateAllSiteSortPayload(data: any){
    this.allTeamRosterSortObj = {...data};
  }

  @Mutation
  mutateSiteSortPayload(data:any){
    this.authKey = APP_UTILITIES.authTokenKeyToManage() ? APP_UTILITIES.authTokenKeyToManage() : "";
    let accountId: any = APP_UTILITIES.getCookie('accountId');
    let programId: any = APP_UTILITIES.getCookie('programId') ? APP_UTILITIES.getCookie('programId') : 0;
    let siteId:any = APP_UTILITIES.getCookie("siteId") ? APP_UTILITIES.getCookie("siteId") : 0;
    let payload: any = {};
  
    this.allTeamRosterSortObj = { ...this.allTeamRosterSortObj, [accountId]: this.allTeamRosterSortObj[accountId]? this.allTeamRosterSortObj[accountId] : {}};

    let payloadPro = { ...this.allTeamRosterSortObj[accountId], [programId]: this.allTeamRosterSortObj[accountId][programId]? this.allTeamRosterSortObj[accountId][programId] : {}};

    let payloadSite = { ...payloadPro[programId], [siteId]: JSON.parse(JSON.stringify(data))};

    let payloadtempAcc = { ...this.allTeamRosterSortObj[accountId], [programId]: payloadSite};
    payload = { ...this.allTeamRosterSortObj, [accountId]: payloadtempAcc};

    APP_UTILITIES.setFilteredColumns(`${this.authKey}-AllTeamRosterSortPayload`, payload);
  }

  @Mutation
  mutateAllSiteSearchPayload(data: any){
    this.allTeamRosterSearchObj = {...data};
  }

  @Mutation
  mutateDownloadingState(data:boolean){
    this.downloadState = data;
  }

  @Mutation
  mutateSitesSearchObj(data: any) {
    this.authKey = APP_UTILITIES.authTokenKeyToManage() ? APP_UTILITIES.authTokenKeyToManage() : "";
    let accountId: any = APP_UTILITIES.getCookie('accountId');
    let programId: any = APP_UTILITIES.getCookie('programId') ? APP_UTILITIES.getCookie('programId') : 0;
    let siteId:any = APP_UTILITIES.getCookie("siteId") ? APP_UTILITIES.getCookie("siteId") : 0;
    let payload: any = {};
  
    this.allTeamRosterSearchObj = { ...this.allTeamRosterSearchObj, [accountId]: this.allTeamRosterSearchObj[accountId]? this.allTeamRosterSearchObj[accountId] : {}};

    let payloadPro = { ...this.allTeamRosterSearchObj[accountId], [programId]: this.allTeamRosterSearchObj[accountId][programId]? this.allTeamRosterSearchObj[accountId][programId] : {}};

    let payloadSite = { ...payloadPro[programId], [siteId]: JSON.parse(JSON.stringify(data))};

    let payloadtempAcc = { ...this.allTeamRosterSearchObj[accountId], [programId]: payloadSite};
    payload = { ...this.allTeamRosterSearchObj, [accountId]: payloadtempAcc};

    APP_UTILITIES.setFilteredColumns(`${this.authKey}-AllTeamRosterSearchPayload`, payload);
  }
}

export default getModule(TaskPlannerModule);