import {Vue} from 'vue-property-decorator'
import * as screentext from '@/lang/en/screentext.json'

export class ScreenText extends Vue{

    public getScreenText(key:string):string{
        let strScreenText:string = (<any> screentext.en)[key];
        return strScreenText;
    }

    public getScreenTextWithParams(key: string, ...params: string[]): string {
      let strScreenText: string = (<any>screentext.en)[key];

      if (!params.length) {
          return strScreenText;
      }

      return params.reduce((text, param, index) => {
        return text.replace(`{${index}}`, param);
      }, strScreenText);
    }

}