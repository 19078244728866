import { Vue, Component, Prop } from 'vue-property-decorator';
import TypeIcon from '../../assets/images/cms/star.svg';
import NumberKidsIcon from '../../assets/images/cms/child.svg';
import GradeRangeIcon from '../../assets/images/cms/child-reaching.svg';
import TimeIcon from '../../assets/images/cms/clock.svg';
import ClockHours from '../../assets/images/cms/clock_hours_star.svg';
import Calendar from '../../assets/images/cms/calendar.svg';

/**
 * This order of this enum determines the order of the labels
 */
export enum LabelType {
  TYPE,
  GRADE_RANGE,
  NUMBER_OF_KIDS,
  TIME,
  CLOCK_HOURS,
  DATE
}

export type LabelData = {
  type: LabelType;
  title: string;
  value: string;
};

export const exampleLabelData: Array<LabelData> = [
  {
    type: LabelType.TYPE,
    title: 'Type: ',
    value: 'Activity • Talk Time • Around the World: Games and Play'
  },
  {
    type: LabelType.NUMBER_OF_KIDS,
    title: 'Number of Kids: ',
    value: 'Individual'
  },
  {
    type: LabelType.GRADE_RANGE,
    title: 'Grade Range: ',
    value: 'Pre K, K, 1, 2, 3, 4, 5, 6, 7, 8',
  },
  {
    type: LabelType.TIME,
    title: 'Time: ',
    value: '30min - 1hr 20min'
  },
  {
    type: LabelType.CLOCK_HOURS,
    title: 'Clock Hours: ',
    value: '30min'
  }
];

@Component({
  name: 'cms-content-label',
  props: {
    labelData: {
      type: Array
    }
  }
})
export default class CmsLabelComponent extends Vue {
  @Prop({
    type: Array,
  })
  labelData!: Array<LabelData>;

  typeIcon = TypeIcon;
  numberKidsIcon = NumberKidsIcon;
  gradeRangeIcon = GradeRangeIcon;
  timeIcon = TimeIcon;

  beforeMount() {
    // Order labelData array by the enum LabelType
    this.labelData.sort((a, b) => {
      return a.type - (b.type);
    }
    );
  }

  getIconForLabelType(labelType: LabelType): string {
    switch (labelType) {
      case LabelType.TYPE:
        return TypeIcon;
      case LabelType.NUMBER_OF_KIDS:
        return NumberKidsIcon;
      case LabelType.GRADE_RANGE:
        return GradeRangeIcon;
      case LabelType.TIME:
        return TimeIcon;
      case LabelType.CLOCK_HOURS:
        return ClockHours;
      case LabelType.DATE:
        return Calendar;
      default:
        return '';
    }
  }


}
