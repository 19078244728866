import APP_UTILITIES from "@/utilities/commonFunctions";

/**
 * Route guard used to check if a route is enabled by a feature flag.
 * @param to The route to navigate to.
 * @param from The route the user is coming from.
 * @param next The function to call to continue to the next route.
 * @returns boolean | next
 */
export default async function flagGuard(to: any, from: any, next: any) {
  const flagKey = to.meta.flag;
  if (flagKey) {
    try {
      await APP_UTILITIES.waitForLDClient();
      const flagValue = await APP_UTILITIES.getFeatureFlag(flagKey);
      if (!flagValue) {
        console.warn(`Route ${to.path} disabled by feature flag.`);
        return false;
      } else {
        return next();
      }
    } catch {
      console.error("Error fetching feature flag value.");
      return next(new Error("Error fetching feature flag value."));
    }
  } else {
    return true;
  }
}
