import { render, staticRenderFns } from "./ProgramList.vue?vue&type=template&id=f6ecee92&scoped=true&"
import script from "./ProgramList.vue?vue&type=script&lang=ts&"
export * from "./ProgramList.vue?vue&type=script&lang=ts&"
import style0 from "./ProgramList.less?vue&type=style&index=0&id=f6ecee92&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f6ecee92",
  null
  
)

export default component.exports